import { useEffect, useMemo, useState } from 'react';

export const useDetector = () => {

    const [operationSystem, setOS] = useState("")

    const userAgent = useMemo(() => navigator.userAgent || navigator.vendor || window.opera, []);


    useEffect(() => {

        const getMobileOperatingSystem = () => {
            if (/android/i.test(userAgent)) {
                return "Android";
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            } else {
                return "Web Desktop";
            }
        };

        const detectedOS = getMobileOperatingSystem();
        setOS(detectedOS)
    }, []);


    return {
        os: operationSystem
    }

}