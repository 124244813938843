import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ApiCalling } from '../../../Services/Pool';

const AlreadySubscribed = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(window.location.search);
    const transactionId = searchParams.get('TId');

    const [token, setToken] = useState('');
    const [lang, setLang] = useState('');

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    const isAndroid = /android/i.test(userAgent);
    const playStoreMarketLink = 'market://details?id=com.srifamilyguide.app';
    const appStoreMarketLink = 'https://apps.apple.com/in/app/sri-family-guide/id6478222135';

    useEffect(() => {
        getTransactionByCheckUser();
    }, []);


    // const redirectToAppStoreOrPlayStore = () => {
    //     if (isIOS) {
    //         // Redirect to App Store if app is not installed
    //         window.location.href = appStoreMarketLink; // Replace with your actual App Store URL
    //     } else if (isAndroid) {
    //         // Check if the app is installed
    //         // window.location.href = 'intent://com.srifamilyguide.app/#Intent;scheme=myapp;package=com.srifamilyguide.app;end';
    //         window.location.href = playStoreMarketLink;
    //     }
    // };

    const getTransactionByCheckUser = async () => {

        const user = JSON.parse(localStorage.getItem("user"));
        // console.log("moible", user?.data?.mobile);
        try {
            const response = await fetch(
                `${ApiCalling.base_url_v1}${ApiCalling.paths.checkUser}`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(user?.data?.mobile),
                }
            );
            const responseData = await response.json();

        }
        catch (error) {
            console.log("Err while calling checkUser");
        }
    }



    useEffect(() => {
        const storedToken = localStorage.getItem('myTokenKey');
        if (storedToken) {
            const parsedToken = JSON.parse(storedToken);
            const token = parsedToken.token;
            const lang = parsedToken.lang;
            setToken(token);
            setLang(lang);
        }
    }, []);

    // const downloadInvoice = async()=>{
    //     setLoader(true);

    // }


    return (
        <>
            <div style={{ width: "85%", margin: "auto" }}>
                <div style={{ marginTop: "100px", marginBottom: "100px", textAlign: "center", padding: "40px" }}>
                    <Icon.CheckCircleFill style={{ fontSize: 50, color: "#FD6E47", marginBottom: "20px" }} />
                    <h1 style={{ marginBottom: "20px", color: "#FD6E47", fontWeight: "600", fontSize: "35px" }}>Your are already subscribed !</h1>
                    {/* <h3 style={{ color: "grey" }}>Your are already subscribed !</h3> */}
                    
                    <br />
                    <br />
                    <div>
                        <p>Download the app now!</p>
                    </div>
                    {!isIOS ? (
                        <a href={playStoreMarketLink}>
                            <img height={64} src="https://srifamilyguide.com/landing-web/assets/images/google-play.png" />
                        </a>
                    ) : (
                        <a href={appStoreMarketLink}>
                            <img height={64} src="https://srifamilyguide.com/landing-web/assets/images/app-store.png" />
                        </a>
                    )}
                </div>
            </div>
        </>

    )
}

export default AlreadySubscribed;
