import React, { useEffect, useState } from 'react'
import headerImage from "../../../Assets/headerimage.png"
import familygLogo from "../../../Assets/familylogo.png"
import Back from "../../../Assets/back.png"
import { Button, Form, InputGroup } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import ReuseCode from './ReuseComponent/ReuseCode'
import ReuseButton from './ReuseComponent/ReuseButton'
import * as Icon from 'react-bootstrap-icons';
import { ApiCalling } from '../../../Services/Pool'
import { showToastError, showToastSuccess, showToastWarning } from '../../../Utilities/ToastUtils'
import useGetLabels from '../../CustomHookes/useGetLabels'

const SetPassword = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const [payload, setPayload] = useState(location.state?.payload);

    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [newPasswordIcon, setNewPasswordIcon] = useState(true)
    const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(true)

    const [newPassError, setNewPassError] = useState(false)
    const [confirmPassError, setConfirmPassError] = useState(false)

    // state for language change
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("language"));

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
    };


    // for call custom hook
    const { getAllLabels } = useGetLabels();


    let ensetPasswordScreenLabels, hnsetPasswordScreenLabels, mrsetPasswordScreenLabels;

    if (getAllLabels && getAllLabels.length > 0) {
        const labels = getAllLabels[0][selectedLanguage?.toLowerCase()];
        if (labels) {
            ensetPasswordScreenLabels = labels.setPasswordScreen;
            hnsetPasswordScreenLabels = labels.setPasswordScreen;
            mrsetPasswordScreenLabels = labels.setPasswordScreen;
        }
    }

    const handleValidate = () => {
        let validate = true
        if (newPassword?.length === 0) {
            validate = false
            setNewPassError(true)
        }
        if (confirmPassword?.length === 0) {
            validate = false
            setConfirmPassError(true)
        }
        return validate
    }

    const registerUser = async () => {
        if (handleValidate()) {
            const url = ApiCalling.base_url_v1;
            const constant = `/user/register`;
            const method = "POST";
            const reqBody = payload;
            const res = await fetch(url + constant, {
                method: method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(reqBody),
            });
            const data = await res.json();
            if (data?.userState === "registered") {
                await login();
            } else {
                showToastError("User is already registered.")
            }
        }
    }

    const handleRegisterUser = () => {
        if (handleValidate()) {
            if (newPassword && confirmPassword) {
                if (newPassword === confirmPassword) {
                    payload.password = newPassword;
                    // setPayload({ ...payload, password: newPassword });
                    registerUser();
                } else {
                    showToastWarning("Passwords do not match")
                }
            }
        }
    };

    const login = async () => {
        const url = ApiCalling.base_url_v1
        const constant = `/user/login`
        const method = "POST"
        const reqBody = {
            "mobile": payload.mobile,
            "password": payload.password,
        }

        const res = await fetch(url + constant, {
            method: method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(reqBody),
        });
        const data = await res.json();
        if (data?.user?.userState == "registered") {
            showToastSuccess("Password set successfully.")
            localStorage.setItem('token', JSON.stringify(data.token));
            localStorage.setItem("user", JSON.stringify(payload))
            navigate(`/event-details/${data.token}/${selectedLanguage}`)
        }
    }

    //   const resetPassword = async (password) => {

    //     const url = API.BASE_URL
    //     const constant = `v1/user/setPassword`
    //     const method = "PATCH"
    //     const reqBody = {
    //       "mobile": route.params?.mobile,
    //       "password": password
    //     }
    //     const res = await apiCall(url, constant, method, reqBody).then(res => {
    //       if (res.data?.success == true) {
    //         navigation.reset({
    //           index: 0,
    //           routes: [{ name: 'LoginScreen' }]
    //         })
    //         Toast.show("Password change successfully")
    //       } else if (res.data?.message == "User not found") {
    //         Toast.show("User not found")
    //       } else {
    //         Toast.show("Error while password update")
    //       }
    //     }).catch(err => {
    //       console.log("Error while password update", err)
    //       Toast.show("Error while password update")
    //     })
    //   }

    return (
        <>
            <ReuseCode
                headerImage={headerImage}
                Back={Back}
                familygLogo={familygLogo}
                title={selectedLanguage === "mr" ? mrsetPasswordScreenLabels?.title : selectedLanguage === "hn" ? hnsetPasswordScreenLabels?.title : ensetPasswordScreenLabels?.title}
                endPoint="/register"
                onLanguageChange={handleLanguageChange}
            />
            <div className='mobileinputfield'>
                <p>{selectedLanguage === "mr" ? mrsetPasswordScreenLabels?.userMsg : selectedLanguage === "hn" ? hnsetPasswordScreenLabels?.userMsg : ensetPasswordScreenLabels?.userMsg}</p>

                <Form className='passwordForm'>
                    <InputGroup className="mb-3">
                        {newPassError ? <p style={{ color: "red" }}> *Please enter minimum 8 characters</p> : null}
                        <Form.Control
                            type={newPasswordIcon ? 'text' : 'password'}
                            placeholder={selectedLanguage === "mr" ? mrsetPasswordScreenLabels?.newPasswordPlaceholder : selectedLanguage === "hn" ? hnsetPasswordScreenLabels?.newPasswordPlaceholder : ensetPasswordScreenLabels?.newPasswordPlaceholder}
                            value={newPassword}
                            onChange={(e) => {
                                setNewPassword(e.target.value.replace(/[\s]/, ''));
                            }}
                            style={{ border: '1px solid #FFD7CD' }}
                        />
                        {newPasswordIcon ?
                            <Icon.Eye className='passwordIcon' onClick={() => setNewPasswordIcon(!newPasswordIcon)} name="eye-off" size={20} color={'#B3B3B3'} /> :
                            <Icon.EyeSlash className='passwordIcon' onClick={() => setNewPasswordIcon(!newPasswordIcon)} name="eye" size={20} color={'#B3B3B3'} />

                        }
                    </InputGroup>

                    <InputGroup className="mb-3">
                        {confirmPassError ? <p style={{ color: "red" }}> *Please enter minimum 8 characters</p> : null}
                        <Form.Control
                            placeholder={selectedLanguage === "mr" ? mrsetPasswordScreenLabels?.confirmPasswordPlaceholder : selectedLanguage === "hn" ? hnsetPasswordScreenLabels?.confirmPasswordPlaceholder : ensetPasswordScreenLabels?.confirmPasswordPlaceholder}
                            type={confirmPasswordIcon ? 'text' : 'password'}
                            value={confirmPassword}
                            onChange={(e) => { setConfirmPassword(e.target.value.replace(/[\s]/, '')) }}
                            style={{ border: '1px solid #FFD7CD' }}
                        />
                        {confirmPasswordIcon ?
                            <Icon.Eye className='passwordIcon' onClick={() => setConfirmPasswordIcon(!confirmPasswordIcon)} name="eye-off" size={20} color={'#B3B3B3'} /> :
                            <Icon.EyeSlash className='passwordIcon' onClick={() => setConfirmPasswordIcon(!confirmPasswordIcon)} name="eye" size={20} color={'#B3B3B3'} />

                        }
                    </InputGroup>

                </Form>
            </div>

            <div style={{ width: "90%", margin: '60px auto' }}>
                <button className="primaryBtn" onClick={handleRegisterUser}>
                    {selectedLanguage === "mr" ? mrsetPasswordScreenLabels?.saveBtn : selectedLanguage === "hn" ? hnsetPasswordScreenLabels?.saveBtn : ensetPasswordScreenLabels?.saveBtn}
                </button>
            </div>

        </>
    )
}

export default SetPassword
