import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import { useNavigate, useLocation, useParams } from 'react-router';
import styles from "./PaymentFail.module.css";

const TIME = 15 * 1000;


const PaymentFail = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const searchParams = new URLSearchParams(window.location.search);
    const transactionId = searchParams.get('TId');
    console.log("tra", transactionId);

    const [token, setToken] = useState('');
    const [lang, setLang] = useState('');

    useEffect(() => {
        const storedToken = localStorage.getItem('myTokenKey');
        if (storedToken) {
            const parsedToken = JSON.parse(storedToken);
            const token = parsedToken.token;
            const lang = parsedToken.lang;
            setToken(token);
            setLang(lang);
        }
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            handleRedirect();
        }, TIME);
        return () => {
            clearTimeout(timeout)
        }
      }, []);
    
      const handleRedirect = () => {
        window.location.href = "https://srifamilyguide.com"
      };


    const handleTryAgain = () => {
        localStorage.removeItem('myTokenKey');
        navigate(`/courses-details/${token}/${lang}`);
        // window.location.href = `https://sfg.foxberry.live/courses-details/${token}/${lang}`;
    };



    return (
        <>
            <div style={{ width: "85%", margin: "auto" }}>
                <div style={{ marginTop: "200px", marginBottom: "200px", textAlign: "center", padding: "40px" }}>
                    <Icon.ExclamationTriangleFill style={{ fontSize: 50, color: "red", marginBottom: "20px" }} />
                    <h1 style={{ marginBottom: "20px", color: "grey", fontWeight: "600", fontSize: "35px" }}>Transaction Failed</h1>
                    <h3 style={{ color: "grey" }}>Please try a different payment method</h3>
                    <p className='fw-bold'>Transaction Id: <span>{transactionId}</span></p>
                    <div>
                    </div>
                    <div>
                        <br />
                        <button className={styles.button} onClick={handleRedirect} type="button">Redirect to website</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentFail
