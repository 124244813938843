const environments = {
    master: {
        baseUrl: "https://sfg.foxberry.live/v1",
    },
    privacyterms: {
        baseUrl: "https://api.sfg.foxberry.live/v1",
    },
    eventphase1: {
        baseUrl: "https://api.sfg.foxberry.live/v1",
    }
};

export const ApiCalling = {
    base_url_v1: 'https://api.srifamilyguide.com/v1',
    // base_url_staging_v1: "https://stagingapi.sfg.foxberry.live/v1",
    paths: {
        initiatePayment: '/pay/initiatePayment',
        getEventForUser: '/event/getEventForUser',
        bookEvent: '/event/eventRegistration',
        getLabels: '/labels/getAppLabels',
        getEvents: '/event/getEvents',
        getCourseForUser: '/course/getCourseForUser',
        sendOTP: '/user/sendVerfCode',
        verifyOtp: '/user/verifyCode',
        verifyOtpLogin: '/user/verifyCodeLogin',
        checkUser: "/user/checkUser",
        loginUser: "/user/login",
        getToken: "/user/getToken",
        initiatePaymentLogin :'/pay/initiatePaymentLogin',
        getInvoice:'/pay/getInvoice'

    },
};
 