import React, { useEffect, useRef, useState } from 'react';
import sriimg from "../../../Assets/image 17843.png";
import familyImg from "../../../Assets/Isolation_Mode.png";
import * as Icon from "react-bootstrap-icons";
import groupImg from "../../../Assets/Group 1707479703.png";
import Slider from 'react-slick';
import Dada from "../../../Assets/satsang.svg"
import Shrim from "../../../Assets/vishava.svg"
import Tambe from "../../../Assets/TambeFrame.png"
import Flower from "../../../Assets/image 17844.png"
import Vishava from "../../../Assets/vishwa-wellness-logo 1.svg"
import Satsang from "../../../Assets/TSF_logo_white_600px 2.svg"
import SakalLogo from "../../../Assets/sakal-digital-2 1.png"
import Aagni from "../../../Assets/Isolation_Mode (1).png";
import Book from "../../../Assets/book.png";
import swasthyam from "../../../Assets/swasthyam.png";
import backgroundShade from "../../../Assets/backgroundshade.svg"
import backImg from "../../../Assets/backimg.svg"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import eventBG from "../../../Assets/eventBg.svg";
import eventIMG from "../../../Assets/eventImg.svg"
import Location from "../../../Assets/Location.svg"
import Calender from "../../../Assets/Calendar.svg"

const LandingPageOld = () => {
    const sliderRef = useRef(null);

    const navigate = useNavigate()


    // for selected item
    const [selectedImage, setSelectedImage] = useState(1);

    const gradientStyle = {
        background: 'linear-gradient(to top, #FFD2C4, #FFEEE9, #FFFCFB)'
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        easing: 'easeInOutQuad',
        autoplay: true,
        autoplaySpeed: 3000,

        beforeChange: (currentSlide, nextSlide) => {
            const isVideoSlide = checkIfVideoSlide(currentSlide);
            if (isVideoSlide) {
                sliderRef.current.slickPause();
            }
        },
        afterChange: (currentSlide) => {
            const isVideoSlide = checkIfVideoSlide(currentSlide);
            if (!isVideoSlide) {
                sliderRef.current.slickPlay();
            }
        },
        customPaging: function (i) {
            return <div className='custom-dot'></div>;
        },
    };

    const checkIfVideoSlide = (slideIndex) => {
        return slideIndex === 1 || slideIndex === 3;
    };

    const handleSlideClick = () => {
        sliderRef.current.slickNext();
    };

    const options = {
        items: 3,
        loop: true,
        center: true,
        margin: 10,
        dots: true,
    };

    // for images click rotation
    const rotateArray = (direction) => {
        setImages((prevImages) => {
            const rotatedArray = [...prevImages];
            if (direction === "clockwise") {
                const lastElement = rotatedArray.pop();
                rotatedArray.unshift(lastElement);
            } else if (direction === "counterclockwise") {
                const firstElement = rotatedArray.shift();
                rotatedArray.push(firstElement);
            }
            return rotatedArray;
        });
    };
    const handleImageClick = (index) => {
        setSelectedImage(index);

        const currentIndex = Math.floor(images.length / 2);
        const diff = index - currentIndex;

        if (diff !== 0) {
            const direction = diff > 0 ? "clockwise" : "counterclockwise";
            rotateArray(direction === "clockwise" ? "counterclockwise" : "clockwise");
        }
    };

    // for images
    const [images, setImages] = useState([
        {
            src: Dada,
            description: 'A recipient of Padma Bhushan Award, Sri M is an Indian Yogi, Spiritual Guide, Orator, and Educationist. He is a disciple of many esteemed gurus from the region of Himalayas. The founder of Satsang Foundation, he believes that to achieve wellbeing, one needs to transcend the outer shell and nurture the innate goodness.',
            image: Satsang
        },
        {
            src: Tambe,
            description: `A recipient of Padma Shri, Dr. Balaji Tambe is an Ayurvedic Doctor,
                    Spiritual Guide, and also an expert in Music and Yoga Therapy. He is the founder of
                    Atmasantulana Village, located in Pune. He is of the belief that –
                    “Ayurveda is not merely a medical solution; instead, it is a way of life to achieve a healthy mind, body, and soul.”`,
            image: Flower
        },
        {
            src: Shrim,
            description: 'Dr. Purushottam Rajimwale was born in Shivpuri (Akkalkot), in the family of Param Sadguru Gajanan Maharaj. He is the global head of Shri Swami Samartha Paduka Math (Shri Guru Mandir) and Vishwa Foundation. He is on the mission of healing humanity and creating a better planet using ‘Agnihotra’, a proven Vedic process, as a way of life.',
            image: Vishava
        }
    ])

    // for shift images
    const renderImages = () => {
        return images.map((image, index) => {
            const isActive = index === Math.floor(images.length / 2);
            const blurStyle = isActive ? {} : { filter: 'blur(1px)' };
            const scale = isActive ? 1.5 : 1;
            const blur = isActive ? 'none' : 'blur(1px)';

            return (
                <motion.div
                    key={index}
                    className={`image-item ${isActive ? 'selected' : ''}`}
                    style={{ transform: `scale(${scale})`, filter: blur, marginTop: '150px', marginBottom: '60px', transition: 'transform 0.5s ease-in-out', height: '80vh' }}
                >
                    <motion.div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            transition: 'transform 0.5s ease-in-out'
                        }}
                        key={index}
                    >
                        <motion.img
                            src={image.src}
                            alt={`Image ${index + 1}`}
                            style={{ width: '300px', height: '300px', ...blurStyle }}
                            onClick={() => handleImageClick(index)}
                        />

                        {isActive &&
                            <motion.p
                                className='mt-2'
                                style={{
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    textAlign: 'center',
                                    width: "auto",
                                    margin: 'auto'
                                }}
                            >{image.description}</motion.p>
                        }
                        {image.image && (
                            <motion.div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', display: isActive ? "" : "none" }}>
                                <img src={image.image} alt={`Image ${index} Symbol`} style={{ width: '100px' }} />
                            </motion.div>
                        )}
                    </motion.div>
                </motion.div>
            );
        });
    };


    return (
        <>
            <div className='langingPage' style={gradientStyle}>
                {/* section-1 */}
                <div className='section-1 ' >
                    <div className='backgroundSection pb-3' style={{ background: `url(${backgroundShade}) center/cover no-repeat` }}>

                        <div className="d-flex justify-content-between align-items-center p-3 px-5" style={{ background: `url(${backImg}) center/cover no-repeat` }} >
                            <img src={sriimg} alt='img' style={{ width: '200px' }} />
                            <img src={familyImg} alt='img' style={{ width: '100px' }} />
                            <button className="downloadBtn">Download App<Icon.ArrowRight className='ms-2' /></button>
                        </div>

                        <div className="d-flex justify-content-between align-items-center event-Wrapper p-5 my-5" style={{ background: `url(${eventBG}) center/cover no-repeat` }} >
                            <div className='w-50' >
                                <img src={eventIMG} style={{ width: '350px' }} />
                            </div>
                            <div className='w-50'>
                                <h6 style={{
                                    fontSize: '20px', fontWeight: '400', color: '#2B2B2B', fontFamily: "Devanagari Sangam MN"
                                }}>
                                    सामाजिक, आध्यात्मिक, शारीरिक आणि आर्थिक प्रगती करण्याचा
                                </h6>
                                <h1 style={{ fontSize: '40px', fontWeight: '700', color: '#2B2B2B', fontFamily: "Devanagari Sangam MN", textAlign: 'center' }}>संकल्प ते सिद्धी सोहळा !</h1>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <p style={{ fontSize: '12px', fontWeight: '400' }}><img src={Calender} /><span> २६ मार्च २०२४ - २७ मार्च २०२४</span></p>
                                    <p style={{ fontSize: '12px', fontWeight: '400', marginLeft: '20px' }}><img src={Location} /><span> सिडको एक्सहिबिशन सेंटर, वाशी, नवी मुंबई</span></p>
                                </div>

                                <p style={{ fontSize: '35px', fontWeight: '700', color: '#882425', fontFamily: "Devanagari Sangam MN", textAlign: 'center' }}>प्रवेश विनामूल्य</p>

                                <div className='mt-5' style={{ display: 'flex', justifyContent: 'center' }}>
                                    <button className="downloadBtn" onClick={() => navigate("/event-page")}>Register Now<Icon.ArrowRight className='ms-2' /></button>
                                </div>
                            </div>
                        </div>

                        <div className='section-1-header mt-5'>
                            <h1 className='m-0 p-0'>Holistic Lifestyle</h1><h1>Guidance Program</h1>
                        </div>

                        <div className='section-1-desc my-5 px-5'>
                            <h6 className='m-0 p-0'>The Journey of Success & Self Happiness.</h6>
                            <p>Often, success is co-related with happiness. Happiness is subjective and the pursuit of happiness is a complex landscape of self expectations, hard work, and material desires. However, as we journey through life, we come to realize that happiness is not a destination to reach, rather it is a way of life.</p>
                        </div>

                        <div className="d-flex justify-content-center mt-5 my-md-3">
                            <img src={groupImg} style={{ width: "25%" }} />
                        </div>
                        {/* <div className="d-flex justify-content-center mt-3 my-md-3">
                            <button className=" px-2 m-0 downloadBtn" onClick={() => navigate("/event-page")}>Event Section</button>
                        </div> */}
                    </div>
                    <div className='familyImage p-3 d-flex justify-content-start align-items-end'>
                        <p style={{ fontSize: '25px', color: 'white', verticalAlign: 'end', width: '40vw', fontWeight: '400', padding: '20px' }}>Often, success is co-related with happiness. Happiness is subjective and the pursuit of happiness is a complex landscape of self expectations, hard work, and material desires. However, as we journey through life, we come to realize that happiness is not a destination to reach, rather it is a way of life.</p>
                    </div>
                </div>


                {/* section-2 */}
                <div className="my-5">
                    <p className="m-0 p-0" style={{ textAlign: " center", fontWeight: 400, fontSize: "40px", fontFamily: '"Exo 2", sans-serif' }}>PILLARS OF SRI FAMILY GUIDE</p>
                    <div className="image-slider" >
                        <div className="image-container">{renderImages()}</div>
                    </div>
                </div>

                {/* section-3 */}
                <div style={{
                    border: "1px solid #A7A7A7",
                    padding: "20px 40px",
                    borderRadius: "10px",
                    margin: "12px 80px",
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "space-between",
                    height: '500px'
                    // ...gradientStyle
                }}>
                    <div className='mt-5  ms-5'>
                        <img src={SakalLogo} style={{ width: "200px", height: "100px" }} />
                    </div>
                    <div className="mt-5" style={{ maxWidth: "calc(50% - 80px)" }}>
                        <p style={{ fontSize: "18px", fontWeight: '600', fontFamily: '"Exo 2", sans-serif' }}>Esteemed as India’s distinguished media company, commitment at Sakal Media Group,
                            extends beyond business success. With a steadfast belief in the power of business for good,
                            Sakal Media integrates social responsibility and community wellness into the fabric of everything it undertakes.
                        </p>
                        <p style={{ fontSize: "14px", fontWeight: '400', fontFamily: '"Exo 2", sans-serif' }}>From our diverse portfolio, we actively seek opportunities to uplift and empower communities.</p>
                        <p style={{ fontSize: "14px", fontWeight: '400', fontFamily: '"Exo 2", sans-serif' }}>Whether it is the ‘Family Doctor’ pustika, Arogya Sakal Expo, or Swasthyam event,
                            we address pressing lifestyle issues and try to impact lives in a positive way.
                            As an upswing of all these activities, we now come ahead with a holistic lifestyle guidance program –‘Sri Family Guide’.</p>
                    </div>
                </div>


                {/* section-4 slider*/}
                <div className="my-5">
                    <p style={{ textAlign: " center", fontWeight: 400, fontSize: "40px", fontFamily: '"Exo 2", sans-serif' }}>Features of the Program</p>
                    <div className='mainContainer slider-wrapper' style={{ width: '80%', margin: 'auto' }}>
                        <OwlCarousel className='owl-theme' loop margin={4} dots dotClass="owl-dot-red">
                            <div class='item' style={{ width: '90%' }}>
                                <div onClick={handleSlideClick} className="slider-item">
                                    <h4 className="m-0 p-0" style={{ fontWeight: 700, fontSize: "20px", fontFamily: '"Exo 2", sans-serif' }}>Course <br />Material</h4>
                                    <p className="m-0 p-0 mt-2" style={{ fontWeight: 400, fontSize: "14px" }}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less </p>
                                    <div style={{ display: "flex", justifyContent: "end" }}>
                                        <img src={Book} style={{ width: "20%" }} />
                                    </div>
                                </div>
                            </div>
                            <div class='item' style={{ width: '90%' }}>
                                <div onClick={handleSlideClick} className="slider-item">
                                    <h4 className="m-0 p-0" style={{ fontWeight: 700, fontSize: "20px", fontFamily: '"Exo 2", sans-serif' }}>Course <br />Material</h4>
                                    <p className="m-0 p-0 mt-2" style={{ fontWeight: 400, fontSize: "14px" }}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less </p>
                                    <div style={{ display: "flex", justifyContent: "end" }}>
                                        <img src={Aagni} style={{ width: "20%" }} />
                                    </div>
                                </div>
                            </div>
                            <div class='item' style={{ width: '90%' }}>
                                <div onClick={handleSlideClick} className="slider-item">
                                    <h4 className="m-0 p-0" style={{ fontWeight: 700, fontSize: "20px", fontFamily: '"Exo 2", sans-serif' }}>Course <br />Material</h4>
                                    <p className="m-0 p-0 mt-2" style={{ fontWeight: 400, fontSize: "14px" }}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less </p>
                                    <div style={{ display: "flex", justifyContent: "end" }}>
                                        <img src={Book} style={{ width: "20%" }} />
                                    </div>
                                </div>
                            </div>
                            <div class='item' style={{ width: '90%' }}>
                                <div onClick={handleSlideClick} className="slider-item">
                                    <h4 className="m-0 p-0" style={{ fontWeight: 700, fontSize: "20px", fontFamily: '"Exo 2", sans-serif' }}>Course <br />Material</h4>
                                    <p className="m-0 p-0 mt-2" style={{ fontWeight: 400, fontSize: "14px" }}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less </p>
                                    <div style={{ display: "flex", justifyContent: "end" }}>
                                        <img src={Aagni} style={{ width: "20%" }} />
                                    </div>
                                </div>
                            </div>

                        </OwlCarousel>
                    </div>
                </div>

                {/* section-5 slider*/}
                <div className="my-5" style={{ height: '100vh' }}>
                    <p style={{ textAlign: " center", fontWeight: 400, fontSize: "40px", fontFamily: '"Exo 2", sans-serif' }}>Community Events</p>

                    <div className='mainContainer slider-wrapper' style={{ margin: "15px" }}>
                        <Slider ref={sliderRef} {...settings} >
                            <div onClick={handleSlideClick}>
                                <img src={swasthyam} style={{ width: '90%', height: '100%', objectFit: 'cover', margin: '0 auto', borderRadius: '5px' }} />
                            </div>

                            <div onClick={handleSlideClick}>
                                <img src={swasthyam} style={{ width: '90%', height: '100%', objectFit: 'cover', margin: '0 auto', borderRadius: '5px' }} />
                            </div>

                            <div onClick={handleSlideClick}>
                                <img src={swasthyam} style={{ width: '90%', height: '100%', objectFit: 'cover', margin: '0 auto', borderRadius: '5px' }} />
                            </div>

                            <div onClick={handleSlideClick}>
                                <img src={swasthyam} style={{ width: '90%', height: '100%', objectFit: 'cover', margin: '0 auto', borderRadius: '5px' }} />
                            </div>
                        </Slider>
                    </div>
                </div>

                {/* contact section */}
                <div className='mb-5 px-5'>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "25%" }}>
                        <img src={familyImg} style={{ width: '200px' }} />
                        <img className='mt-2' src={sriimg} alt='img' style={{ width: '150px' }} />
                    </div>
                    <div className="mt-4">
                        <p style={{ fontWeight: 500, fontSize: "25px", textAlign: "center", lineHeight: "30px", fontFamily: '"Exo 2", sans-serif' }}>To Join Sri Family Guide Contact Us Here</p>
                    </div>

                    <div className='d-flex justify-content-betwen align-items-center px-3'>
                        <div className="w-25" style={{ borderRight: '2px solid red', width: '150px' }}>
                            <p >
                                <span style={{ fontSize: '14px', color: '#1E1E1E', fontWeight: '400' }}>Call to Know More:</span>
                                <p className='m-0 p-0' ><a href="tel:+918956344471" style={{ textDecoration: 'none', color: '#2B2B2B', fontSize: '12px', fontWeight: '700' }}>+91 8956344471</a></p>
                            </p>
                        </div>

                        <div className='px-2 w-50' style={{ borderRight: '2px solid red' }}>
                            <p >
                                <span style={{ fontSize: '14px', color: '#1E1E1E', fontWeight: '400' }}>Simances Learning LLP:</span>
                                <p className='m-0 p-0' style={{ color: '#2B2B2B', fontSize: '12px', fontWeight: '700' }}>82/1A & 83/1A, Sakal Nagar, Gate no. 1, Baner Road, Anudh, Pune, Maharashtra(411007)</p>
                            </p>
                        </div>

                        <div className='px-2 w-25'>
                            <p>
                                <span style={{ fontSize: '14px', color: '#1E1E1E', fontWeight: '400' }}>Write Us: </span>
                                <p className='m-0 p-0' ><a href="mailto:info@srifamilyguide.com" style={{ textDecoration: 'none', color: '#2B2B2B', fontSize: '12px', fontWeight: '700' }}>info@srifamilyguide.com</a></p>
                            </p>
                        </div>
                    </div>
                </div>

                {/* footer section */}
                <div style={{ display: "flex", backgroundColor: "#000000", justifyContent: "space-between", alignItems: "center", padding: "15px 10px" }}>
                    <p className="p-0 m-0" style={{ fontWeight: 400, fontSize: "12px", fontFamily: '"Exo 2", sans-serif', color: '#fff' }}>All Rights Reserved</p>
                    <p className="p-0 m-0" style={{ fontWeight: 400, fontSize: "14px", fontFamily: '"Exo 2", sans-serif', color: '#fff' }} >Copyright Sakal Media Group</p>
                    <p className="p-0 m-0" style={{ fontWeight: 400, fontSize: "12px", fontFamily: '"Exo 2", sans-serif', color: '#fff' }}>Designed with <span>🤍</span> by</p>
                </div>
            </div >
        </>
    )
}

export default LandingPageOld
