import React, { useRef, useState } from 'react'
import Invoice from '../../Invoice/Invoice';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SliderImage from "../../../../Assets/SFG.jpeg"
import { Button, Modal, Spinner } from 'react-bootstrap';
import PageNotFound from '../../PageNotFound/PageNotFound';
import Dada from "../../../../Assets/Dada.png"
import Srim from "../../../../Assets/SriM.png"
import Tambe from "../../../../Assets/Tambe.png"
import SomethingWrong from '../../PageNotFound/SomethingWrong';
import Download from "../../../../Assets/invoicedownload.png";
import { TermsContent } from '../../../TermsContent/TermsContent';

const CourseCard = ({ handlerPayment, image, courseName, courseDescription, duration, purchaseStatus, btnClass, loader, courseIncludesLabel, coursePurchased, enrollNow, expertVideos, instructors, DurationLabel, invoiceLink, keyPoint, courseIncludes, instructorsDetails, apierror, apiLabelError, courseData, lang, labels }) => {

    const jsxContent = <Invoice />
    const sliderRef = useRef(null);
    const [autoplay, setAutoplay] = useState(true);

    // for agree checkbox
    const [agreeTerms, setAgreeTerms] = useState(false);

    // for modal
    const [showModal, setShowModal] = useState(false);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // autoplay: false,
        // autoplaySpeed: 3000,
        easing: 'easeInOutQuad',
        beforeChange: (currentSlide, nextSlide) => {
            const isVideoSlide = checkIfVideoSlide(currentSlide); // Check if the current slide is a video slide
            if (isVideoSlide) {
                sliderRef.current.slickPause();
            }
        },
        afterChange: (currentSlide) => {
            const isVideoSlide = checkIfVideoSlide(currentSlide); // Check if the current slide is a video slide
            if (!isVideoSlide) {
                sliderRef.current.slickPlay();
            }
        },
        customPaging: function (i) {
            return (
                <div style={{ width: '10px', height: '10px', background: '#333', borderRadius: '50%' }}>

                </div>
            );
        },
    };

    const checkIfVideoSlide = (slideIndex) => {
        return slideIndex === 1 || slideIndex === 3;
    };

    const handleSlideClick = () => {
        sliderRef.current.slickNext();
    };

    // for color array
    const colors = ['#FF9A83', '#FFDEA9', '#2F9177', '#B1D3FC', '#FF9A83', '#FFDEA9', '#FF9A83',  '#B1D3FC', '#FFDEA9', '#2F9177',];

    // for check box check and uncheck
    const handleCheckboxChange = () => {
        setAgreeTerms(!agreeTerms);
    };

    // Open modal function
    const handleShowModal = () => {
        setShowModal(true);
    };

    // Close modal function
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const ln = {
        MR: 'mr',
        EN: 'en',
        HN: 'hn',
    }


    const generateTermsLink = (lang = 'mr') => {
        switch (lang) {
            case ln.MR:
                return <span style={{ marginLeft: '10px' }}>मला सर्व <a href="#" onClick={handleShowModal}>नियम व् अटी</a> मान्य आहेत</span>
            case ln.EN:
                return <span style={{ marginLeft: '10px' }}>I agree to the <a href="#" onClick={handleShowModal}>Terms and Conditions</a></span>
            case ln.HN:
                return <span style={{ marginLeft: '10px' }}>मैं <a href="#" onClick={handleShowModal}>नियमों और शर्तों</a> से सहमत हूं</span>
            default:
                return <span style={{ marginLeft: '10px' }}>मला सर्व <a href="#" onClick={handleShowModal}>नियम व् अटी</a> मान्य आहेत</span>

        }
    }

    const getTermsByLang = lang => {
        switch (lang) {
            case ln.MR:
                return "नियम आणि अटी"
            case ln.EN:
                return "Terms and Conditions"
            case ln.HN:
                return "नियम और शर्तें"
            default:
                return "नियम आणि अटी"

        }
    }

    const getFeesByLang = lang => {
        switch (lang) {
            case ln.MR:
                return "शुल्क : रु. ११०००/- करांसहित"
            case ln.EN:
                return "Fee: Rs. 11000/- inclusive of taxes"
            case ln.HN:
                return "शुल्क: रु. ११०००/- टैक्स सहित"
            default:
                return "नियम आणि अटी"

        }
    }

    return (
        <>
            {
                loader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                    <Spinner animation="border" role="status" >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
                    : (
                        apierror || apiLabelError ? (
                            < SomethingWrong />

                        ) :

                            <>

                                <div className='mainContainer slider-wrapper' style={{ overflowY: 'scroll', height: '88vh' }}>
                                    <Slider ref={sliderRef} {...settings}>
                                        <div className='imageContainer mt-3' onClick={handleSlideClick}>
                                            <img src={SliderImage} alt='image' className="w-100" style={{ borderRadius: "20px" }} />
                                        </div>
                                        <div className='imageContainer mt-3' onClick={handleSlideClick}>
                                            <div style={{ position: 'relative', paddingTop: '56.25%' }}>
                                                <iframe
                                                    src="https://customer-bzo8e8xg81cat4dt.cloudflarestream.com/56057646d4aa36803451dfc1117ccabd/iframe?poster=https%3A%2F%2Fcustomer-bzo8e8xg81cat4dt.cloudflarestream.com%2F56057646d4aa36803451dfc1117ccabd%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
                                                    loading="lazy"
                                                    style={{ border: 'none', position: 'absolute', top: '0', left: '0', height: '100%', width: '100%' }}
                                                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                                                    allowfullscreen="true"
                                                ></iframe>
                                            </div>
                                        </div>
                                        <div className='imageContainer mt-3' onClick={handleSlideClick}>
                                            <img src={SliderImage} alt='image' className="w-100" style={{ borderRadius: "20px" }} />
                                        </div>
                                        <div className='imageContainer mt-3' onClick={handleSlideClick}>
                                            <div style={{ position: 'relative', paddingTop: '56.25%' }}>
                                                <iframe
                                                    src="https://customer-bzo8e8xg81cat4dt.cloudflarestream.com/56057646d4aa36803451dfc1117ccabd/iframe?poster=https%3A%2F%2Fcustomer-bzo8e8xg81cat4dt.cloudflarestream.com%2F56057646d4aa36803451dfc1117ccabd%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
                                                    loading="lazy"
                                                    style={{ border: 'none', position: 'absolute', top: '0', left: '0', height: '100%', width: '100%' }}
                                                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                                                    allowfullscreen="true"
                                                ></iframe>
                                            </div>
                                        </div>
                                    </Slider>

                                    <div className='eventContainer mt-5' >
                                        <h6 style={{ fontWeight: "600", fontSize: "18px", lineHeight: '21.6px' }}>
                                            {courseName}
                                        </h6>
                                        <p style={{ fontWeight: "400", fontSize: "14px", lineHeight: '20px' }}>{courseDescription}</p>
                                        <ul style={{ listStyleType: 'none', fontSize: '14px', paddingLeft: '0px' }}>
                                            {
                                                keyPoint && keyPoint?.map((ele) => (
                                                    <>
                                                        <li>{ele}</li>
                                                    </>
                                                ))
                                            }

                                        </ul>
                                    </div>

                                    <div>
                                        <p style={{ fontSize: "16px", fontWeight: 500, lineHeight: "19.2px" }}><b>{DurationLabel}</b> :  {duration}</p>
                                    </div>

                                    <div>
                                        <p style={{ fontSize: "18px", fontWeight: 500, lineHeight: "34px" }}>
                                            <b>{expertVideos}</b>
                                        </p>
                                        <div className='imagesContainer'>
                                            <div style={{
                                                position: 'relative',
                                                paddingTop: '56.25%',
                                            }}>
                                                <iframe
                                                    src="https://customer-bzo8e8xg81cat4dt.cloudflarestream.com/56057646d4aa36803451dfc1117ccabd/iframe?poster=https%3A%2F%2Fcustomer-bzo8e8xg81cat4dt.cloudflarestream.com%2F56057646d4aa36803451dfc1117ccabd%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
                                                    loading="lazy"
                                                    style={{ border: 'none', position: 'absolute', top: '0', left: '25px', height: '60', width: '90' }}
                                                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                                                    allowfullscreen="true"
                                                ></iframe>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <p style={{ fontSize: "18px", fontWeight: 500, lineHeight: "34px" }}>
                                            <b>{courseIncludesLabel}</b>
                                        </p>

                                        <div className='text-center' style={{ fontSize: '16px', lineHeight: '22px', fontWeight: '500' }}>
                                            {
                                                courseIncludes && courseIncludes?.map((ele, index) => (

                                                    <>
                                                        <div
                                                            key={index}
                                                            className='mt-3'
                                                            style={{ borderRadius: '10px', backgroundColor: colors[index], padding: '13px' }}
                                                        >
                                                            {ele}
                                                        </div>
                                                    </>
                                                ))
                                            }

                                        </div>
                                    </div>

                                    <div className='mt-3 instructor-wrapper'>
                                        <p style={{ fontSize: "18px", fontWeight: 500, lineHeight: "34px" }}>
                                            <b>{instructors}</b>
                                        </p>

                                        <div style={{ display: "flex", justifyContent: "space-around", margin: "20px 0px 130px 0px" }}>
                                            {
                                                instructorsDetails && instructorsDetails.map((ele, index) => (
                                                    <>
                                                        <div className='images'>
                                                            <img src={ele?.image} alt="Sri M" />
                                                            <p style={{ fontSize: "15px", textAlign: "center" }}>{ele?.name}</p>
                                                        </div>
                                                    </>
                                                ))
                                            }
                                        </div>

                                    </div>

                                    {labels?.fees && (
                                        <div style={{marginBottom: 50}}>{labels.fees}</div>
                                    )}




                                    <div className='d-flex justify-content-center my-4'
                                        style={{
                                            width: "90%", display: "flex", flexDirection: 'column', justifyContent: "center", marginTop: "30px", marginBottom: "40px",
                                            position: "fixed", bottom: 0, left: "20px"
                                        }}
                                    >

                                        {/* for check box */}
                                        <div style={{ marginBottom: '-10px', padding: '18px 20px', boxSizing: 'border-box', backgroundColor: '#fff' }}>
                                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                                <input type="checkbox" id="termsConditionsCheckbox" checked={agreeTerms} onChange={handleCheckboxChange} style={{ width: '20px', height: '20px' }} />
                                                {generateTermsLink(lang)}
                                            </label>
                                        </div>

                                        <button
                                            // onClick={() => handlerPayment()}
                                            className={!agreeTerms ? "disabledBtn" : btnClass}
                                            disabled={!agreeTerms || purchaseStatus}>
                                            Coming Soon
                                        </button>
                                    </div>

                                    <div style={{ padding: '20px 0px', boxSizing: 'border-box' }}>
                                        {purchaseStatus && (
                                            <div className='mb-5'>
                                                <a
                                                    href={invoiceLink}
                                                    style={{ textDecoration: 'none', color: 'inherit' }}
                                                    download="Invoice.pdf"
                                                >
                                                    <div style={{ fontSize: '18px', fontWeight: '400', lineHeight: '20px', color: '#127E60', textDecoration: 'underline', textAlign: 'left' }}>
                                                        <img src={Download} style={{ width: '25px', height: '25px', objectFit: 'cover' }} alt="Download Icon" />
                                                        <span className='ms-2'>Download Invoice</span>
                                                    </div>
                                                </a>
                                            </div>

                                        )}
                                    </div>



                                    {/* create modal */}
                                    <Modal show={showModal} onHide={handleCloseModal} >
                                        <Modal.Header closeButton>
                                            <Modal.Title>{getTermsByLang(lang)}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{ maxHeight: '80vh', overflowY: 'auto', padding: '20px', backgroundColor: '#E5E4E2' }}>
                                            <TermsContent />
                                            <div style={{ height: 100 }}></div>
                                        </Modal.Body>
                                    </Modal>
                                </div>

                            </>
                    )

            }

        </ >
    )
}

export default CourseCard

