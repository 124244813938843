import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ApiCalling } from '../../../Services/Pool';
import CourseCard from './Card/CourseCard';
import useGetLabels from '../../CustomHookes/useGetLabels';
import { showToastWarning } from '../../../Utilities/ToastUtils';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SliderImage from "../../../Assets/SFG.jpeg"
import { Button, ButtonGroup, Spinner } from 'react-bootstrap';
import PageNotFound from '../PageNotFound/PageNotFound';
import SomethingWrong from '../PageNotFound/SomethingWrong';
import Download from "../../../Assets/invoicedownload.png";
import * as Icon from "react-bootstrap-icons";

const CoursesPage = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const { lang } = useParams();

    const sliderRef = useRef(null);
    const [autoplay, setAutoplay] = useState(true);

    // for language changes
    const [selectedLanguage, setSelectedLanguage] = useState("mr");
    const [activeKey, setActiveKey] = useState("mr");

    const user = JSON.parse(localStorage.getItem("user"));
    const localToken = JSON.parse(localStorage.getItem("token"))

    const [token, setToken] = useState("")

    // state for payment
    const [paymentLink, setPaymentLink] = useState('');
    const [coursedata, setCourseData] = useState('');
    const [purchasedata, setPurchaseData] = useState('');


    // for loader
    const [loader, setLoader] = useState(false);
    const [apierror, setApiError] = useState(false)

    // for local storage data
    localStorage.setItem('myTokenKey', JSON.stringify({ token, lang }));

    // for call custom hook
    const { getAllLabels, setApiLabelError, apiLabelError } = useGetLabels();

    let enCourseLabels, hnCourseLabels, mrCourseLabels;

    if (getAllLabels && getAllLabels.length > 0) {
        const { en, hn, mr } = getAllLabels[0];

        if (en && hn && mr) {
            enCourseLabels = en.courseLabels;
            hnCourseLabels = hn.courseLabels;
            mrCourseLabels = mr.courseLabels;

        }
    }


    // for button click
    const handleButtonClick = (key) => {
        setActiveKey(key);
        setSelectedLanguage(key);
    };


    // useEffect(() => {
    //     getCourseData();
    //     getToken()
    // }, [token]);

    useEffect(() => {
        if (localToken !== null) {
            navigate(`/courses-details/${localToken}/${selectedLanguage}`)
        }
    }, [])

    // get event details
    const getCourseData = async () => {
        try {
            setLoader(true);
            const response = await fetch(
                `${ApiCalling.base_url_v1}${ApiCalling.paths.getCourseForUser}`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json", "authorization": token },
                }
            );

            const responseData = await response.json();

            if (responseData) {
                setCourseData(responseData?.courseData);
                setPurchaseData(responseData?.purchaseData);
                setLoader(false);
            }
        } catch (error) {
            console.error("Error fetching course data:", error);
            setLoader(false);
            setApiError(true)
            setApiLabelError(true)
        }
    };

    // get token
    const getToken = async () => {
        setLoader(true);
        try {
            const response = await fetch(
                `${ApiCalling.base_url_v1}${ApiCalling.paths.getToken}`,
                {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                }
            );
            const responseData = await response.json();
            setToken(responseData)
        } catch (error) {
            setLoader(false);
            setApiError(true);
            setApiLabelError(true);
        }
    };


    // for slider
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // autoplay: false,
        // autoplaySpeed: 3000,
        easing: 'easeInOutQuad',
        beforeChange: (currentSlide, nextSlide) => {
            const isVideoSlide = checkIfVideoSlide(currentSlide); // Check if the current slide is a video slide
            if (isVideoSlide) {
                sliderRef.current.slickPause();
            }
        },
        afterChange: (currentSlide) => {
            const isVideoSlide = checkIfVideoSlide(currentSlide); // Check if the current slide is a video slide
            if (!isVideoSlide) {
                sliderRef.current.slickPlay();
            }
        },
        customPaging: function (i) {
            return (
                <div style={{ width: '10px', height: '10px', background: '#333', borderRadius: '50%' }}>

                </div>
            );
        },
    };

    const checkIfVideoSlide = (slideIndex) => {
        return slideIndex === 1 || slideIndex === 3;
    };

    const handleSlideClick = () => {
        sliderRef.current.slickNext();
    };

    // for color array
    const colors = ['#FF9A83', '#FFDEA9', '#2F9177', '#B1D3FC', '#FF9A83'];


    return (
        <>
            {
                loader ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                        <Spinner animation="border" role="status" >
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                    : (
                        apierror || apiLabelError ? (
                            < SomethingWrong />

                        ) :

                            <div
                                className="mainContainer"
                                style={{ overflowX: "scroll", height: "80vh" }}
                            >
                                <div className="my-3 mx-2 d-flex justify-content-between">
                                    <ButtonGroup
                                        aria-label="Basic example"
                                        style={{
                                            border: "1px solid black",
                                            borderRadius: "50px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Button
                                            variant="light"
                                            className="border-right"
                                            active={activeKey === "mr"}
                                            onClick={() => handleButtonClick("mr")}
                                            style={{
                                                backgroundColor:
                                                    activeKey === "mr" ? "#FD6E47" : "#FCFFFF",
                                                color: activeKey === "mr" ? "#FCFFFF" : "#000",
                                                borderRight: "1px solid black",
                                            }}
                                        >
                                            MR
                                        </Button>
                                        <Button
                                            variant="light"
                                            className="border-right"
                                            active={activeKey === "hn"}
                                            onClick={() => handleButtonClick("hn")}
                                            style={{
                                                backgroundColor:
                                                    activeKey === "hn" ? "#FD6E47" : "#FCFFFF",
                                                color: activeKey === "hn" ? "#FCFFFF" : "#000",
                                                borderRight: "1px solid black",
                                            }}
                                        >
                                            HN
                                        </Button>
                                        <Button
                                            variant="light"
                                            className="border-right"
                                            active={activeKey === "en"}
                                            onClick={() => handleButtonClick("en")}
                                            style={{
                                                backgroundColor:
                                                    activeKey === "en" ? "#FD6E47" : "#FCFFFF",
                                                color: activeKey === "en" ? "#FCFFFF" : "#000",
                                                borderLeft: "1px solid black",
                                            }}
                                        >
                                            EN
                                        </Button>
                                    </ButtonGroup>
                                    {user === null ? (
                                        <Button
                                            style={{
                                                background: "#FD6E47",
                                                width: 100,
                                                marginRight: 10,
                                                padding: 10,
                                                border: 'none'
                                            }}
                                            onClick={() => {
                                                navigate("/mobilenumber");
                                            }}
                                        >
                                            Login <Icon.ArrowBarRight size={20} />
                                        </Button>
                                    ) : null}
                                </div>
                                <div className='mainContainer slider-wrapper' style={{ overflowY: 'scroll', height: '88vh' }}>
                                    <Slider ref={sliderRef} {...settings}>
                                        <div className='imageContainer mt-3' onClick={handleSlideClick}>
                                            <img src={SliderImage} alt='image' className="w-100" style={{ borderRadius: "20px" }} />
                                        </div>
                                        <div className='imageContainer mt-3' onClick={handleSlideClick}>
                                            <div style={{ position: 'relative', paddingTop: '56.25%' }}>
                                                <iframe
                                                    src="https://customer-bzo8e8xg81cat4dt.cloudflarestream.com/56057646d4aa36803451dfc1117ccabd/iframe?poster=https%3A%2F%2Fcustomer-bzo8e8xg81cat4dt.cloudflarestream.com%2F56057646d4aa36803451dfc1117ccabd%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
                                                    loading="lazy"
                                                    style={{ border: 'none', position: 'absolute', top: '0', left: '0', height: '100%', width: '100%' }}
                                                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                                                    allowfullscreen="true"
                                                ></iframe>
                                            </div>
                                        </div>
                                        <div className='imageContainer mt-3' onClick={handleSlideClick}>
                                            <img src={SliderImage} alt='image' className="w-100" style={{ borderRadius: "20px" }} />
                                        </div>
                                        <div className='imageContainer mt-3' onClick={handleSlideClick}>
                                            <div style={{ position: 'relative', paddingTop: '56.25%' }}>
                                                <iframe
                                                    src="https://customer-bzo8e8xg81cat4dt.cloudflarestream.com/56057646d4aa36803451dfc1117ccabd/iframe?poster=https%3A%2F%2Fcustomer-bzo8e8xg81cat4dt.cloudflarestream.com%2F56057646d4aa36803451dfc1117ccabd%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
                                                    loading="lazy"
                                                    style={{ border: 'none', position: 'absolute', top: '0', left: '25px', height: '60', width: '90' }}
                                                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                                                    allowfullscreen="true"
                                                ></iframe>
                                            </div>
                                        </div>
                                    </Slider>

                                    <div className='eventContainer mt-5' >
                                        <h6 style={{ fontWeight: "600", fontSize: "18px", lineHeight: '21.6px' }}>
                                            {selectedLanguage === "mr" ? coursedata?.mar?.courseName : selectedLanguage === "hn" ? coursedata?.hn?.courseName : coursedata?.en?.courseName}
                                        </h6>
                                        <p style={{ fontWeight: "400", fontSize: "14px", lineHeight: '20px' }}>{selectedLanguage === "mr" ? coursedata?.mar?.description : selectedLanguage === "hn" ? coursedata?.hn?.description : coursedata?.en?.description}</p>
                                        <ul style={{ listStyleType: 'none', fontSize: '14px', paddingLeft: '0px' }}>
                                            {
                                                selectedLanguage === "mr" ? coursedata?.mar?.keyPoints : selectedLanguage === "hn" ? coursedata?.hn?.keyPoints : coursedata?.en?.keyPoints && selectedLanguage === "mr" ? coursedata?.mar?.keyPoints : selectedLanguage === "hn" ? coursedata?.hn?.keyPoints : coursedata?.en?.keyPoints?.map((ele) => (
                                                    <>
                                                        <li>{ele}</li>
                                                    </>
                                                ))
                                            }

                                        </ul>
                                    </div>

                                    <div>
                                        <p style={{ fontSize: "16px", fontWeight: 500, lineHeight: "19.2px" }}><b>{selectedLanguage === 'mr' ? mrCourseLabels?.duration : selectedLanguage === "hn" ? hnCourseLabels?.duration : enCourseLabels?.duration}</b> :  {selectedLanguage === "mr" ? coursedata?.mar?.duration : selectedLanguage === "hn" ? coursedata?.hn?.duration : coursedata?.en?.duration}</p>
                                    </div>

                                    <div>
                                        <p style={{ fontSize: "18px", fontWeight: 500, lineHeight: "34px" }}>
                                            <b>{selectedLanguage === 'mr' ? mrCourseLabels?.expertVideos : selectedLanguage === "hn" ? hnCourseLabels?.expertVideos : enCourseLabels?.expertVideos}</b>
                                        </p>
                                        <div className='imagesContainer'>
                                            <div style={{
                                                position: 'relative',
                                                paddingTop: '56.25%',
                                            }}>
                                                <iframe
                                                    src="https://customer-bzo8e8xg81cat4dt.cloudflarestream.com/56057646d4aa36803451dfc1117ccabd/iframe?poster=https%3A%2F%2Fcustomer-bzo8e8xg81cat4dt.cloudflarestream.com%2F56057646d4aa36803451dfc1117ccabd%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
                                                    loading="lazy"
                                                    style={{ border: 'none', position: 'absolute', top: '0', left: '25px', height: '60', width: '90' }}
                                                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                                                    allowfullscreen="true"
                                                ></iframe>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <p style={{ fontSize: "18px", fontWeight: 500, lineHeight: "34px" }}>
                                            <b>{selectedLanguage === 'mr' ? mrCourseLabels?.courseIncludes : selectedLanguage === "hn" ? hnCourseLabels?.courseIncludes : enCourseLabels?.courseIncludes}</b>
                                        </p>

                                        <div className='text-center' style={{ fontSize: '16px', lineHeight: '22px', fontWeight: '500' }}>
                                            {
                                                selectedLanguage === "mr" ? coursedata?.mar?.courseIncludes : selectedLanguage === "hn" ? coursedata?.hn?.courseIncludes : coursedata?.en?.courseIncludes && selectedLanguage === "mr" ? coursedata?.mar?.courseIncludes : selectedLanguage === "hn" ? coursedata?.hn?.courseIncludes : coursedata?.en?.courseIncludes?.map((ele, index) => (

                                                    <>
                                                        <div
                                                            key={index}
                                                            className='mt-3'
                                                            style={{ borderRadius: '10px', backgroundColor: colors[index], padding: '12px' }}
                                                        >
                                                            {ele}
                                                        </div>
                                                    </>
                                                ))
                                            }

                                        </div>
                                    </div>

                                    <div className='mt-3 instructor-wrapper'>
                                        <p style={{ fontSize: "18px", fontWeight: 500, lineHeight: "34px" }}>
                                            <b>{selectedLanguage === 'mr' ? mrCourseLabels?.instructors : selectedLanguage === "hn" ? hnCourseLabels?.instructors : enCourseLabels?.instructors}</b>
                                        </p>

                                        <div style={{ display: "flex", justifyContent: "space-around", margin: "20px 0px 70px 0px" }}>
                                            {
                                                (selectedLanguage === "mr" ? coursedata?.mar?.instructorsDetails : selectedLanguage === "hn" ? coursedata?.hn?.instructorsDetails : coursedata?.en?.instructorsDetails)?.map((ele, index) => (
                                                    <div key={index} className='images'>
                                                        <img src={ele?.image} alt={ele?.name} />
                                                        <p style={{ fontSize: "15px", textAlign: "center" }}>{ele?.name}</p>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                    </div>

                                    <div className='d-flex justify-content-center my-4'
                                        style={{
                                            width: "90%", display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "40px",
                                            position: "fixed", bottom: 0, left: "20px"
                                        }}
                                    >
                                        <button
                                            // onClick={() => handlerPayment()}
                                            className={purchasedata?.isPurchase === true ? "disabledBtn" : "primaryBtn"}
                                            disabled={purchasedata?.isPurchase}>
                                            {purchasedata?.isPurchase === false ? selectedLanguage === 'mr' ? mrCourseLabels?.enrollNow : selectedLanguage === "hn" ? hnCourseLabels?.enrollNow : enCourseLabels?.enrollNow : selectedLanguage === 'mr' ? mrCourseLabels?.coursePurchased : selectedLanguage === "hn" ? hnCourseLabels?.coursePurchased : enCourseLabels?.coursePurchase}
                                        </button>
                                    </div>

                                    <div style={{ padding: '20px 0px', boxSizing: 'border-box' }}>
                                        {purchasedata?.isPurchase && (
                                            <div className='mb-5'>
                                                <a
                                                    href={purchasedata?.invoiceLink}
                                                    style={{ textDecoration: 'none', color: 'inherit' }}
                                                    download="Invoice.pdf"
                                                >
                                                    <div style={{ fontSize: '18px', fontWeight: '400', lineHeight: '20px', color: '#127E60', textDecoration: 'underline', textAlign: 'left' }}>
                                                        <img src={Download} style={{ width: '25px', height: '25px', objectFit: 'cover' }} alt="Download Icon" />
                                                        <span className='ms-2'>Download Invoice</span>
                                                    </div>
                                                </a>
                                            </div>

                                        )}
                                    </div>

                                </div>

                            </div>
                    )

            }
        </>
    )
}


export default CoursesPage;
