import { usePillars } from "./usePillars"
import "./pillar.css"

export const Pillars = () => {

    const { pillars, selectPillar, isSelected } = usePillars();

    return <div className="pillar-container">
        <div className="d-flex flex-direction-row justify-content-between" style={{gap: '10px'}}>

            {pillars.map((pillar, index) => <img onClick={() => {
                if (index === 1) return;
                selectPillar(pillar)
            }} key={`pillar-${index}`} className={`pillar-image ${index === 1 ? 'selected-pillar' : 'deselected-pillar'}`} src={pillar.src} />)}


        </div>
        <div style={{ textAlign: 'center' }}>
            <div className="pillar-description">{pillars[1].description}</div>
            <img className="pillar-brand" src={pillars[1].image} />
        </div>
    </div>
}