import { ApiCalling } from "../../Services/Pool";

const SendOtp = async (mobile, selectedLanguage) => {

    try {
        const payload = { "mobile": mobile, "lang": selectedLanguage };

        const response = await fetch(
            `${ApiCalling.base_url_v1}${ApiCalling.paths.sendOTP}`,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(payload)
            }
        );

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error("Error sending OTP:", error);
        throw error;
    }
};

export { SendOtp };
