import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';

import headerImage from "../../../Assets/headerimage.png";
import familygLogo from "../../../Assets/familylogo.png";
import Back from "../../../Assets/back.png";
import ReuseCode from './ReuseComponent/ReuseCode';
import { ApiCalling } from '../../../Services/Pool';
import { showToastError, showToastSuccess } from '../../../Utilities/ToastUtils';

const Login = () => {
    const navigate = useNavigate()
    // state for mobile and password
    const [mobile, setMobile] = useState("");
    const [password, setPassword] = useState("");
    const [loader, setLoader] = useState(false)
    const [showPass, setShowPass] = useState(false);

    const isMobileValid = mobile.length === 10;
    const isPasswordValid = password.length >= 8;

    // for login
    const loginUser = async () => {
        const payload = {
            "mobile": mobile,
            "password": password,

        }
        try {
            const response = await fetch(
                `${ApiCalling.base_url_v1}${ApiCalling.paths.loginUser}`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(payload),
                }
            );
            const responseData = await response.json();
            if (responseData?.user?.userState == "registered") {
                localStorage.setItem('token', JSON.stringify(responseData?.token));
                localStorage.setItem("user", JSON.stringify(responseData?.user))
                showToastSuccess("Login Successfully")
                navigate(`/courses-details/${responseData?.token}/en`)

            }
        }
        catch (error) {
            showToastError("Error while sending OTP");
        }

    }


    // localStorage.setItem('token', JSON.stringify(data.token));
    // localStorage.setItem("user", JSON.stringify(payload))
    // navigate(`/courses-details/${data.token}/en`)

    return (
        <>
            <ReuseCode headerImage={headerImage} Back={Back} familygLogo={familygLogo} title={<><h4>Sign In</h4></>} endPoint="/signinup" />
            <div className='mobileinputfield'>
                <Form className='passwordForm my-3'>
                    <InputGroup className="mb-3" >
                        <Form.Control
                            placeholder="Mobile number"
                            onChange={e => setMobile(e.target.value.replace(/[^0-9]/g, ''))}
                            maxLength={10}
                            type="tel"
                            style={{ border: '1px solid #FFD7CD' }}
                        />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <Form.Control
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type={showPass ? "text" : "password"}
                            placeholder="Password"
                            style={{ border: '1px solid #FFD7CD' }}
                        />

                        {showPass ? (
                            <Icon.EyeSlash
                                className='passwordIcon'
                                onClick={() => setShowPass(!showPass)}
                                size={20}
                                color={'#B3B3B3'}
                            />
                        ) : (
                            <Icon.Eye
                                className='passwordIcon'
                                onClick={() => setShowPass(!showPass)}
                                size={20}
                                color={'#B3B3B3'}
                            />
                        )}

                    </InputGroup>
                </Form>
                <div style={{ textDecoration: 'none', color: '#2C2B2B', fontSize: '14px', fontWeight: '500', cursor: 'pointer' }} onClick={() => navigate("/forgotpsw")}>
                    Forgot password?
                </div>

                <div className='mt-5'>
                    <p style={{ fontSize: '14px', fontWeight: '400', lineHeight: '16px' }}>By continuing, you agree to our <Link style={{ textDecoration: 'none' }}>Terms of Service </Link> and <Link style={{ textDecoration: 'none' }}>Privacy Policy.</Link></p>
                </div>
            </div>

            {/* <div style={{ width: "90%", margin: '20px auto' }}>
                <button className={`${isPasswordValid && isMobileValid ? 'primaryBtn' : 'disabledBtn'}`} disabled={!isPasswordValid || !isMobileValid} onClick={loginUser}>
                    Sign in
                </button>
            </div> */}

            <div style={{ width: "90%", margin: '10px auto' }}>
                <button className={`${isMobileValid ? 'secondaryBtn' : 'disabledBtn-1'}`} disabled={!isMobileValid} onClick={() => navigate("/mobilenumber")}>
                    Sign in with OTP
                </button>
            </div>
        </>
    );
};

export default Login;
