import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ApiCalling } from '../../../Services/Pool';
import { showToastError } from '../../../Utilities/ToastUtils';

const PaymentSuccess = () => {


    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(window.location.search);
    const transactionId = searchParams.get('TId');
    

    const [token, setToken] = useState('');
    const [lang, setLang] = useState('');
    const [loader, setLoader] = useState(false);

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    const isAndroid = /android/i.test(userAgent);
    const playStoreMarketLink = 'market://details?id=com.srifamilyguide.app';
    const appStoreMarketLink = 'https://apps.apple.com/in/app/sri-family-guide/id6478222135';

    // useEffect(() => {
    //     getInvoice();
    // }, []);


    // const redirectToAppStoreOrPlayStore = () => {
    //     if (isIOS) {
    //         // Redirect to App Store if app is not installed
    //         window.location.href = appStoreMarketLink; // Replace with your actual App Store URL
    //     } else if (isAndroid) {
    //         // Check if the app is installed
    //         // window.location.href = 'intent://com.srifamilyguide.app/#Intent;scheme=myapp;package=com.srifamilyguide.app;end';
    //         window.location.href = playStoreMarketLink;
    //     }
    // };
    useEffect(() => {
        const storedToken = localStorage.getItem('myTokenKey');
        if (storedToken) {
            const parsedToken = JSON.parse(storedToken);
            const token = parsedToken.token;
            const lang = parsedToken.lang;
            setToken(token);
            setLang(lang);
        }
    }, []);

    const getInvoice = async () => {
        setLoader(true);
        try {
            const result = await fetch(
                `${ApiCalling.base_url_v1}${ApiCalling.paths.getInvoice}`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json", 
                                'Authorization':`Bearer ${token}` },
                    body: JSON.stringify({ txnid:transactionId }),
                }
            );
            const response = await result.json();
            if(response?.data!==null){
                downloadFile(response?.data);
            }
            else{
                showToastError('Failed to download, Please try again later.');
            }
            setLoader(false);
        }
        catch (error) {
            console.log("Err while calling checkUser");
            setLoader(false);
            showToastError('Failed to download, Please try again later.');
        }
    }


    const downloadFile = (url) => {
        console.log("url", url);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = String(url)?.split('/')[String(url)?.split('/').length - 1];
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      };





    // const downloadInvoice = async()=>{
    //     setLoader(true);
    // }


    return (
     
            <div className='paymentSuccess' style={{ width: "85%", margin: "auto" }}>
                <div style={{ marginTop: "40px", marginBottom: "100px", textAlign: "center", padding: "30px" }}>
                    <Icon.CheckCircleFill style={{ fontSize: 50, color: "green", marginBottom: "10px" }} />
                    <h1 style={{ marginBottom: "20px", color: "green", fontWeight: "600", fontSize: "35px" }}>Payment  Successful</h1>
                    <h3 style={{ color: "grey" }}>Thank You! Your payment is complete</h3>
                    <p className='fw-bold'>Transaction Id: <span>{transactionId}</span></p>
                    
                    <h5 className='text-center mb-0' style={{color:'#FD6E47'}}>
                        "Payment invoice will be sent to your mobile via SMS shortly"
                    </h5>

                    {/* {
                        loader ? <center><Spinner variant="dark"/></center> :
                        <Button className='downloadBtn mt-3 mx-auto w-auto' onClick={getInvoice}>
                            <Icon.Receipt className='me-2'/>Download Invoice
                        </Button>
                    } */}

                    <br />
                    <br />
                    <div>
                        <p>Please login to app to get plan access !</p>
                        <p>Download the app now!</p>
                    </div>
                    {!isIOS ? (
                        <a href='https://play.google.com/store/apps/details?id=com.srifamilyguide.app'>
                            <img height={64} src="https://srifamilyguide.com/landing-web/assets/images/google-play.png" />
                        </a>
                    ) : (
                        <a href='https://apps.apple.com/in/app/sri-family-guide/id6478222135'>
                            <img height={64} src="https://srifamilyguide.com/landing-web/assets/images/app-store.png" />
                        </a>
                    )}
                </div>
            </div>
    )
}

export default PaymentSuccess
