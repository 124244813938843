import React, { useState } from 'react'
import headerImage from "../../../Assets/headerimage.png"
import familygLogo from "../../../Assets/familylogo.png"
import Back from "../../../Assets/back.png"
import { Form, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import ReuseCode from './ReuseComponent/ReuseCode'
import ReuseButton from './ReuseComponent/ReuseButton'
import { showToastError, showToastSuccess, showToastWarning } from '../../../Utilities/ToastUtils'
import { ApiCalling } from '../../../Services/Pool'
import { SendOtp } from '../../CustomHookes/SendOtp'

const ForgotPassword = () => {

    // for enable button
    const navigate = useNavigate()
    const [mobile, setMobile] = useState("");
    const [loader, setLoader] = useState(false)

    const isMobileValid = mobile.length === 10;

    const handleSendOtp = async (mobile) => {
        setLoader(true)
        try {
            const responseData = await SendOtp(mobile);
            if (responseData?.message === "Verification Code Sent To Mobile") {
                showToastSuccess("OTP sent");
                navigate('/authentication', { state: { mobile } });
            } else {
                setLoader(false)
                showToastError("Error while sending OTP");
            }
        } catch (error) {
            showToastError("Error while sending OTP");
        } finally {
            setLoader(false);
        }

    }

    return (
        <>
            <ReuseCode headerImage={headerImage} Back={Back} familygLogo={familygLogo} title={<><h4>Forgot</h4><h4>Password</h4> </>} endPoint="/authentication" />

            <div className='mobileinputfield'>
                <p>Please enter your mobile number to receive a verification code</p>
                <Form>
                    <Form.Control
                        placeholder="Mobile number"
                        onChange={e => setMobile(e.target.value.replace(/[^0-9]/g, ''))}
                        maxLength={10}
                        type="tel"
                        value={mobile}
                        style={{ border: '1px solid #FFD7CD' }}
                    />
                </Form>
            </div>

            <div style={{ width: "90%", margin: '60px auto' }}>
                {/* {loader ? <Spinner size='lg' /> :  */}
                <button className={` ${isMobileValid ? 'primaryBtn' : 'disabledBtn'}`}
                    onClick={() => { handleSendOtp(mobile) }}
                    disabled={!isMobileValid}>
                    {loader ? (
                        <Spinner
                            as="span"
                            animation="border"
                            size="lg"
                            role="status"
                            aria-hidden="true"
                        />
                    ) : (
                        'Send OTP'
                    )}
                </button>

            </div>

            {/* <ReuseButton button=" Send OTP" endPoint="/authentication" /> */}

        </>
    )
}

export default ForgotPassword
