import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ApiCalling } from '../../../Services/Pool';
import CourseCard from './Card/CourseCard';
import useGetLabels from '../../CustomHookes/useGetLabels';
import { showToastWarning } from '../../../Utilities/ToastUtils';
import { useDetector } from '../UserAgent/useDetector';
import NewCourseCard from './Card/NewCourseCard';

const NewCoursePage = () => {

    const location = useLocation();
    // const { token, lang } = useParams();
    const { os } = useDetector();
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyRGF0YSI6eyJ1c2VyU3RhdGUiOiJyZWdpc3RlcmVkIiwiX2lkIjoiNjVlYWU4MmI1NGM5MzEwNjViMDU0MTMxIiwibmFtZSI6IlNoYW51IiwibW9iaWxlIjoiNzk3Nzg2MDc1NCIsImdlbmRlciI6IuCkquClgeCksOClgeCktyIsImRvYiI6IjE5OTctMDMtMTMiLCJhZGRyZXNzIjoiIiwidmlsbGFnZSI6IiIsImRpc3RyaWN0IjoiUHVuZSIsInBpbmNvZGUiOiIiLCJwcm9mZXNzaW9uIjoiIiwid2hhdHNBcHBOdW1iZXIiOiIiLCJwYXNzd29yZCI6IiQyYSQxMiRNWUg4WnZPVnpSYjk5NDFGVldBMHNlT0VRMVhLV2NTL0FGVXBzYTRTSFZFVE9mVGVnWGFwVyIsImVtYWlsIjoiIiwiY3JlYXRlZEF0IjoiMjAyNC0wMy0wOFQxMDoyNzo1NS4xNDNaIiwidXBkYXRlZEF0IjoiMjAyNC0wMy0xOFQxMjowMTo1MS42MDhaIiwiX192IjowLCJwcm9maWxlUGljIjoiIiwicmVnaXN0cmF0aW9uRGF0ZSI6IjIwMjQtMDMtMTNUMTI6MTM6MzMuNzk0WiIsImZhbWlseU1lbWJlcnMiOlt7Il9pZCI6IjY1ZjgyYmE1YTVkOWVmNjFjYzYxNWVkOSJ9XX0sImlhdCI6MTcxMDc2MzMxMiwiZXhwIjoxNzEyMDU5MzEyfQ.ZwUrfDRqu9bev5nfPn359LtJ_a2BrLF-vxl43swdc20';
    const lang = 'en';
    
    // state for payment
    const [paymentLink, setPaymentLink] = useState('');
    const [coursedata, setCourseData] = useState('');
    const [purchasedata, setPurchaseData] = useState('');

    // for loader
    const [loader, setLoader] = useState(false);
    const [apierror, setApiError] = useState(false)
    const [labels, setLabels] = useState(null)

    // for local storage data
    localStorage.setItem('myTokenKey', JSON.stringify({ token, lang }));

    // for call custom hook
    const { getAllLabels, setApiLabelError, apiLabelError } = useGetLabels();

    let enCourseLabels, hnCourseLabels, mrCourseLabels;

    useEffect(() => {

        if (getAllLabels && getAllLabels.length > 0) {
            const { en, hn, mr } = getAllLabels[0];
            setLabels(getAllLabels[0][lang].courseLabels)

            if (en && hn && mr) {
                enCourseLabels = en.courseLabels;
                hnCourseLabels = hn.courseLabels;
                mrCourseLabels = mr.courseLabels;
            }
        }
    }, [getAllLabels])


    useEffect(() => {
        getCourseData();
    }, [token]);

    // get event details

    const getCourseData = async () => {
        try {
            setLoader(true);
            const response = await fetch(
                `${ApiCalling.base_url_v1}${ApiCalling.paths.getCourseForUser}`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json", "authorization": token },
                }
            );

            const responseData = await response.json();

            if (responseData) {
                setCourseData(responseData?.courseData);
                setPurchaseData(responseData?.purchaseData);
                setLoader(false);
            }
        } catch (error) {
            console.error("Error fetching course data:", error);
            setLoader(false);
            setApiError(true)
            setApiLabelError(true)
        }
    };


    // for payment
    const handlerPayment = async () => {
        // setLoader(true);
        try {
            const payload = {
                "courseId": coursedata?._id,
                "amount": coursedata?.totalPrice,
                "cgst": coursedata?.cgstAmount,
                "sgst": coursedata?.sgstAmount,
                "baseamount": coursedata?.baseAmount,
                "totalTaxOrIGSTAmount": coursedata?.totalTaxOrIGSTAmount,
                "udf5": "",
                "productinfo": coursedata?.en?.courseName,
                "udf6": "",
                "udf7": "",
                "udf8": "",
                "udf9": "",
                "udf10": "",
                "furl": "https://api.sfg.foxberry.live/v1/pay/paymentResponse",
                "surl": "https://api.sfg.foxberry.live/v1/pay/paymentResponse",
                "device": os,
            };

            const response = await fetch(
                `${ApiCalling.base_url_v1}${ApiCalling.paths.initiatePayment}`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json", "authorization": token },
                    body: JSON.stringify(payload),
                }
            );

            const responseData = await response.json();
            if (responseData?.error_desc === "Invalid value for email.") {
                showToastWarning("Please update email address in your profile")
            } else {
                const paymentLink = responseData?.data ? responseData?.data : '';
                setPaymentLink(paymentLink);
                window.location.href = paymentLink;
                setLoader(false)
            }

        } catch (error) {
            console.error("Error handling payment:", error);
            setLoader(false);
            setApiError(true);
            setApiLabelError(true);
        }
    };

    if(!labels) return (<div>Loading</div>)

    return (
        <div className='mt-0'>
            <h2 className='text-center'>Program</h2>
            <NewCourseCard
                handlerPayment={handlerPayment}
                image={coursedata?.image}
                courseName={lang === "mr" ? coursedata?.mar?.courseName : lang === "hn" ? coursedata?.hn?.courseName : coursedata?.en?.courseName}
                courseDescription={lang === "mr" ? coursedata?.mar?.description : lang === "hn" ? coursedata?.hn?.description : coursedata?.en?.description}
                duration={lang === "mr" ? coursedata?.mar?.duration : lang === "hn" ? coursedata?.hn?.duration : coursedata?.en?.duration}
                purchaseStatus={purchasedata?.isPurchase}
                btnClass={purchasedata?.isPurchase === true ? "disabledBtn" : "primaryBtn"}
                loader={loader}
                courseIncludesLabel={labels.courseIncludes}
                coursePurchased={labels.coursePurchased}
                enrollNow={labels.enrollNow}
                expertVideos={labels.expertVideos}
                instructors={labels.instructors}
                DurationLabel={labels.duration}
                invoiceLink={purchasedata?.invoiceLink}
                keyPoint={lang === "mr" ? coursedata?.mar?.keyPoints : lang === "hn" ? coursedata?.hn?.keyPoints : coursedata?.en?.keyPoints}
                courseIncludes={lang === "mr" ? coursedata?.mar?.courseIncludes : lang === "hn" ? coursedata?.hn?.courseIncludes : coursedata?.en?.courseIncludes}
                instructorsDetails={lang === "mr" ? coursedata?.mar?.instructorsDetails : lang === "hn" ? coursedata?.hn?.instructorsDetails : coursedata?.en?.instructorsDetails}
                apierror={apierror}
                labels={labels}
                apiLabelError={apiLabelError}
                courseData={coursedata}
                lang={lang}
            />
        </div>
    );
};

export default NewCoursePage;
