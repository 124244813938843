import React from 'react';
import * as Icon from "react-bootstrap-icons"

const ContactUs = () => {
  return (
    <>
        <div style={{padding:"10px"}}>
        <h1 style={{textAlign:"center",fontWeight:600}}>Contact Us</h1>

        <div style={{marginTop:"25px"}}>
            <p style={{textAlign:"center", fontWeight:500, fontSize:"14px"}}>Any questions or remarks? Just write us a message</p>
        </div>

        <div style={{padding:"20px", margin:"15px", borderRadius:"10px", backgroundColor:"#F8F9FA"}}>
            <div>
                <p><Icon.GeoAltFill size={18} style={{color:"#FD6E47"}}/> <span>SIMACES LEARNING LLP</span></p>
            </div>

            <div>
                <p><Icon.EnvelopeFill size={18} style={{color:"#FD6E47"}}/>  <span>info@srifamilyguide.in</span></p>
            </div>

            <div>
                <p><Icon.TelephoneFill size={18} style={{color:"#FD6E47"}}/>  <span>+91-8956344471</span></p>
            </div>
        </div>
        </div>
    </>
  )
}

export default ContactUs
