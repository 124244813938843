const EmbeddedProject = ({lang = "mr"}) => {

    const url = lang == "en" ? "/landing-web/en/index.html" : "/landing-web/index.html";
  
    return (
      <div className="embedded-project" style={{height: '100vh'}}>
        <iframe height={'100%'} width={'100%'} src={url} title="Embedded Project" />
      </div>
    );
  };
  
  export default EmbeddedProject;