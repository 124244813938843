import React, { Fragment } from 'react'
import "./Terms.css"
import { TermsContent } from '../../TermsContent/TermsContent'

const Terms = () => {
  return (
    <Fragment>

      <div className='mainContainerterm' style={{ padding: 20 }}>
        
        <TermsContent />

      </div>
    </Fragment>
  )
}

export default Terms

