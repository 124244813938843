import React, { useEffect, useState } from 'react';
import { useDetector } from './useDetector';

function UserAgentDetector() {

  const { os } = useDetector();

  return (
    <div>
      User's OS: {os}
    </div>
  );
}

export default UserAgentDetector;
