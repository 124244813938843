import React from 'react';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';

const SomethingWrong = () => {
    return (
        <div className='something-wrapper'>
            <div className='icon-wrapper'>
                <ExclamationTriangleFill className='icon' />
            </div>
            <div className='text-wrapper'>
                <p className='error-text'>Something went wrong</p>
                <p className='info-text'>Please try again later</p>
            </div>
        </div>
    );
};

export default SomethingWrong;
