import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap';

const ButtonToggle = ({ onLanguageChange }) => {

    const [activeKey, setActiveKey] = useState(() => {
        const storedLanguage = localStorage.getItem("language");
        return storedLanguage || "mr";
    });

    useEffect(() => {
        const storedLanguage = localStorage.getItem("language");
        if (!storedLanguage) {
            localStorage.setItem("language", "mr");
        }
    }, []);

    const handleButtonClick = (key) => {
        setActiveKey(key);
        localStorage.setItem("language", key);
        onLanguageChange(key);
    };

    return (
        <>
            <div style={{ marginRight: '15px' }}>
                <ButtonGroup
                    aria-label="Basic example"
                    style={{ border: '1px solid #000', borderRadius: '50px', overflow: 'hidden' }}
                >
                    <Button
                        variant="light"
                        className="border-right"
                        active={activeKey === 'mr'}
                        onClick={() => handleButtonClick('mr')}
                        style={{
                            backgroundColor: activeKey === 'mr' ? '#000' : '#FD6E47',
                            color: activeKey === 'mr' ? '#FFF' : '#000',
                            borderRight: '1px solid #FD6E47',
                        }}
                    >
                        MR
                    </Button>
                    <Button
                        variant="light"
                        className="border-right"
                        active={activeKey === 'hn'}
                        onClick={() => handleButtonClick('hn')}
                        style={{
                            backgroundColor: activeKey === 'hn' ? '#000' : '#FD6E47',
                            color: activeKey === 'hn' ? '#FFF' : '#000',
                            borderRight: '1px solid #000',
                        }}
                    >
                        HN
                    </Button>
                    <Button
                        variant="light"
                        className="border-right"
                        active={activeKey === 'en'}
                        onClick={() => handleButtonClick('en')}
                        style={{
                            backgroundColor: activeKey === 'en' ? '#000' : '#FD6E47',
                            color: activeKey === 'en' ? '#FFF' : '#000'
                        }}
                    >
                        EN
                    </Button>
                </ButtonGroup>
            </div>
        </>
    )
}

export default ButtonToggle
