import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Link, Route, Routes, useLocation } from 'react-router-dom';
import EventPage from './Components/Page/Event/EventPage';
import EventDetails from './Components/Page/Event/EventDetails';
import Terms from './Components/Page/Terms/Terms';
import Privacy from './Components/Page/Privacy/Privacy';
import Invoice from './Components/Page/Invoice/Invoice';
import PageNotFound from './Components/Page/PageNotFound/PageNotFound';
import PaymentFail from './Components/Page/Payment/PaymentFail';
import PaymentSuccess from './Components/Page/Payment/PaymentSuccess';
import CoursesDetails from './Components/Page/Course/CoursesDetails';
import PrimaryPage from './Components/Page/PrimaryPage/PrimaryPage';
import Scanner from './Components/Page/Event/Scanner';
import LandingPage from './Components/Page/PrimaryPage/LandingPage';
import MainLandingPage from './Components/Page/PrimaryPage/MainLandingPage';
import ContactUs from './Components/Page/ContactUs/ContactUs';
import PaymentMode from './Components/Page/Course/PaymentMode/PaymentMode';
import Register from './Components/Page/Register/Register';
import MobileNumber from './Components/Page/Register/MobileNumber';
import Authentication from './Components/Page/Register/Authentication';
import SetPassword from './Components/Page/Register/SetPassword';
import { Nav } from 'react-bootstrap';
import SignInUpScreen from './Components/Page/Register/SignInUpScreen';
import Login from './Components/Page/Register/Login';
import ForgotPassword from './Components/Page/Register/ForgotPassword';
import CoursesPage from './Components/Page/Course/CoursePage';
import LandingPageOld from './Components/Page/PrimaryPage/LandingPage_old';
import EventConfirm from './Components/Page/Event/EventConfirm';
import UserAgentDetector from './Components/Page/UserAgent/detector';
import NewCoursePage from './Components/Page/Course/NewCoursePage';
import ManagePlan from './Components/Page/Course/ManagePlan';
import SuccessPage from './Components/Page/Transaction/Success';
import FailurePage from './Components/Page/Transaction/Failure';
import EmbeddedProject from './EmbeddedLanding';
import AlreadySubscribed from './Components/Page/Payment/AlreadySubscribed';
import LaunchPad from './Components/Page/LaunchPad/LaunchPad';




function App() {

  const isAndroid = /android/i.test(navigator.userAgent);

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const location = useLocation();
  const currentUrl = location.pathname;

  const shouldShowNav = !isAndroid && (isIOS || !navigator.userAgent) && currentUrl.includes("/courses-details") || currentUrl.includes("/event-details");
  // const token = JSON.parse(localStorage.getItem('token'));
  const lang = "en";

  return (

    <div className="App">
      <Routes>
        <Route path='/' element={<EmbeddedProject lang="mr"/>} />
        <Route path='/en' element={<EmbeddedProject lang="en" />} />
        <Route path='/launchpad' element={<LaunchPad />} />
        <Route path='/old' element={<LandingPageOld />} />
        <Route path='.well-known'/>
        {/* <Route path='*' element={<PageNotFound />} /> */}
        <Route path='/register' element={<Register />} />

        <Route path='/mobilenumber' element={<MobileNumber />} />
        <Route path='/authentication' element={<Authentication />} />
        <Route path='/setpassword' element={<SetPassword />} />
        <Route path='/signinup' element={<SignInUpScreen />} />
        <Route path='/login' element={<Login />} />
        <Route path='/forgotpsw' element={<ForgotPassword />} />

        {/* <Route path='/event-details/:token/:lang' element={<EventDetails />} /> */}
        {/* <Route path='/event-page' element={<EventPage />} /> */}
        <Route path="/eventConfirm" element={<EventConfirm />} />
        <Route path='/scanner' element={<Scanner />} />
        <Route path='/course-page' element={<CoursesPage />} />
        <Route path='/courses-details/:token/:lang' element={<CoursesDetails />} />

        <Route path='/program' element={<NewCoursePage />} />
        <Route path='/payment/:plan' element={<ManagePlan />} />
        <Route path='/payment/success' element={<SuccessPage />} />
        <Route path='/payment/failure' element={<FailurePage />} />

        <Route path="/courses-details/paymentmode" element={<PaymentMode />} />
        <Route path='contact-us' element={<ContactUs />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/invoice' element={<Invoice />} />
        <Route path='/paymentsuccess' element={<PaymentSuccess />} />
        <Route path='/paymentfail' element={<PaymentFail />} />
        <Route path='/alreadySubscribed' element={<AlreadySubscribed />} />
        <Route path='/agent' element={<UserAgentDetector />} />
      </Routes>
    </div>
  );
}

export default App;
