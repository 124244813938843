import React, { useEffect, useState } from 'react';
import headerImage from "../../../Assets/headerimage.png";
import familygLogo from "../../../Assets/familylogo.png";
import Back from "../../../Assets/back.png";
import { Form, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ReuseCode from './ReuseComponent/ReuseCode';
import ReuseButton from './ReuseComponent/ReuseButton';
import { showToastError, showToastSuccess, showToastWarning } from '../../../Utilities/ToastUtils';
import { ApiCalling } from '../../../Services/Pool';
import { SendOtp } from '../../CustomHookes/SendOtp';
import useGetLabels from '../../CustomHookes/useGetLabels';

const MobileNumber = () => {
    const navigate = useNavigate();
    const [mobile, setMobile] = useState("");
    const [loader, setLoader] = useState(false);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("language") || "mr");

    const isMobileValid = mobile.length === 10;

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
    };

    const handleSendOtp = async (mobile) => {
        setLoader(true);
        try {
            const responseData = await SendOtp(mobile, selectedLanguage);
            if (responseData?.message === "Verification Code Sent To Mobile") {
                showToastSuccess("OTP sent");
                navigate('/authentication', { state:  mobile });
            } else {
                setLoader(false);
                showToastError("Error while sending OTP");
            }
        } catch (error) {
            showToastError("Error while sending OTP");
        } finally {
            setLoader(false);
        }
    }

    const { getAllLabels } = useGetLabels();
    let welcomeScreenLabels;

    if (getAllLabels && getAllLabels.length > 0) {
        const labels = getAllLabels[0][selectedLanguage.toLowerCase()];
        if (labels) {
            welcomeScreenLabels = labels?.welcomeScreen;
        }
    }

    useEffect(() => {
        setIsButtonEnabled(mobile.length === 10);
    }, [mobile]);

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (isButtonEnabled) {
            handleSendOtp(mobile);
        }
    }

    return (
        <>
            <ReuseCode
                headerImage={headerImage}
                Back={Back}
                familygLogo={familygLogo}
                title={welcomeScreenLabels?.title}
                subTitle={welcomeScreenLabels?.subTitle}
                endPoint="/signinup"
                onLanguageChange={handleLanguageChange}
            />

            <div className='mobileinputfield'>
                <p style={{ lineHeight: "20px" }}>
                    {welcomeScreenLabels?.userMsg}
                </p>
                <Form onSubmit={handleFormSubmit}>
                    <Form.Control
                        placeholder={welcomeScreenLabels?.mobNoPlaceholder}
                        onChange={e => setMobile(e.target.value.replace(/[^0-9]/g, ''))}
                        maxLength={10}
                        type="tel"
                        value={mobile}
                        style={{ border: '1px solid #FFD7CD' }}
                    />
                </Form>
            </div>

            <div style={{ width: "90%", margin: '60px auto' }}>
                <button className={` ${isMobileValid ? 'primaryBtn' : 'disabledBtn'}`}
                    onClick={() => { handleSendOtp(mobile) }}
                    disabled={!isButtonEnabled}>
                    {loader ? (
                        <Spinner
                            as="span"
                            animation="border"
                            size="lg"
                            role="status"
                            aria-hidden="true"
                        />
                    ) : (
                        welcomeScreenLabels?.sendOTP
                    )}
                </button>
            </div>
        </>
    );
}

export default MobileNumber;
