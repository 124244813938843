import React, { useState } from 'react'
import headerImage from "../../../Assets/headerimage.png"
import familygLogo from "../../../Assets/familylogo.png"
import Back from "../../../Assets/back.png"
import { useNavigate } from 'react-router-dom'
import ReuseCode from './ReuseComponent/ReuseCode'
import useGetLabels from '../../CustomHookes/useGetLabels'

const SignInUpScreen = () => {

    const navigate = useNavigate()

    // state for language change
    const [selectedLanguage, setSelectedLanguage] = useState('MR');

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
    };

    // for call custom hook
    const { getAllLabels } = useGetLabels();

    let enEntryPointLabels, hnEntryPointLabels, mrEntryPointLabels;

    if (getAllLabels && getAllLabels.length > 0) {
        const labels = getAllLabels[0][selectedLanguage.toLowerCase()];
        if (labels) {
            enEntryPointLabels = labels.entryPointScreen;
            hnEntryPointLabels = labels.entryPointScreen;
            mrEntryPointLabels = labels.entryPointScreen;
        }
    }


    return (
        <>
            <ReuseCode
                headerImage={headerImage}
                Back={Back}
                familygLogo={familygLogo}
                title={selectedLanguage === "MR" ? mrEntryPointLabels?.title : selectedLanguage === "HN" ? hnEntryPointLabels?.title : enEntryPointLabels?.title}
                endPoint="/signinup"
                onLanguageChange={handleLanguageChange}
            />

            <div style={{ width: "90%", margin: '60px auto' }}>
                <button className='primaryBtn' onClick={() => navigate("/login")}>{selectedLanguage === "MR" ? mrEntryPointLabels?.signInBtn : selectedLanguage === "HN" ? hnEntryPointLabels?.signInBtn : enEntryPointLabels?.signInBtn}</button>
            </div>
            <div style={{ width: "90%", margin: '60px auto' }}>
                <button className='secondaryBtn' onClick={() => navigate("/mobilenumber")}>{selectedLanguage === "MR" ? mrEntryPointLabels?.newRegisterBtn : selectedLanguage === "HN" ? hnEntryPointLabels?.newRegisterBtn : enEntryPointLabels?.newRegisterBtn}</button>
            </div>


        </>
    )
}

export default SignInUpScreen
