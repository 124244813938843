import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import * as Icon from "react-bootstrap-icons"

const Scanner = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const scannerData = location?.state?.scanner;
    const uuidData = location?.state?.uuid;
    const token = location?.state?.token;
    const lang = location?.state?.lang;
    const familyMembers = location?.state?.familyMembers;
    const participateName = location?.state?.participateName;
    return (
        <>
            <div style={{ margin: "10px" }}>
                <button onClick={() => { navigate(`/event-details/${token}/${lang}`) }}
                    style={{ backgroundColor: "#FD6E47", color: "white", padding: "5px 10px", borderRadius: "10px", border: "none" }}>
                    <Icon.ArrowLeft className='me-2' />{lang === "mr" ? "मागे" : lang === "hn" ? "पीछे" : "Back"}
                </button>
            </div>

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <img src={scannerData} style={{ width: "200px", height: "200px" }} />
                <div style={{ marginTop: "15px" }}>
                    <h5>{uuidData}</h5>
                </div>
            </div>

            <div style={{ marginTop: "25px", textAlign: "center" }}>
                <h4><b>Participants name:</b> {participateName?.name}</h4>
                <h4><b>Family Members: </b>{familyMembers} </h4>
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 200
            }}
            onClick={() => {
                localStorage.clear();
                navigate('/')
            }}
            >
                <button className='downloadBtn'> Log Out </button>
            </div>
        </>
    )
}

export default Scanner

