import "./Terms.css"
export const TermsContent = () => {
    return (
        <>

            <center>
                <h1>TERMS AND CONDITIONS</h1>
            </center>


            <h2>I. Introduction</h2>


            <ol type="i">
                <li>Please read the Terms and the privacy policy of this Program (Hereinafter <b>‘Sri Family Guide’</b> in Short <b>‘SFG / Program’</b>) with respect to registration with us, the use of the application, website, services, discourses and products carefully before using the application, website, services, or products.</li>
                <li>In the event of any discrepancy between the Terms and any other policies with respect to the application, website, services, or products, the provisions of the Terms shall prevail. </li>
                <li>Participant’s use/access/browsing of the Application or Website or the Services or products or registration (with or without payment/with or without a subscription) through any means shall signify Participant’s acceptance of the Terms and Participant’s agreement to be legally bound by the same. </li>
                <li>If Participants do not agree with the Terms or the Privacy Policy, please do not use the Application or Website or avail of the Services or products. Any access to our services, applications, or products through registrations/subscriptions is non-transferable.</li>
            </ol>

            <h2>II. Object</h2>

            <ol type="i">
                <li>We want positive change to happen in the society and we feel it from the bottom of our heart. For this, our sincere efforts are underway. As told by our guru we are trying to make a difference in us and trying to take society along for that.  </li>
                <li>To progress spiritually and materially under the guidance of Guru through a Holistic Lifestyle Guidance program</li>
                <li>Sri Family Guide Holistic Lifestyle Guidance program shall strive to try to provide Participants with content that will help Participant be spiritually and financially awakened.</li>
                <li>The need for action to create positive change in society so the Sri Family Guide Holistic Lifestyle Guidance program </li>
            </ol>

            <h2>III. Facilities</h2>

            <ol type="i">
                <li>Weekly lectures, seminars, discussions etc.</li>
                <li>Discourses of Guru’s through video  </li>
                <li>Access to the events organized by the SIMACES and Sakal Media Group.  Access may be paid or free as the case may be.</li>
                <li>Tests to be conducted.  </li>
            </ol>

            <h2>IV. Duration of the course</h2>

            <ol type="i">
                <li>The duration of the course is 52 Weeks i.e. Batch stars from the date of admission/enrollment of the Participant and or payment of entire fees whichever is later and ends on 52 weeks thereafter including the week of admission. The week starts on Monday and ends on Sunday. (Hereinafter <b>‘Batch’</b>)</li>
                <li>The Batch period shall be 52 weeks.  </li>
                <li>In case anybody joins in between the course, however, the same shall end on the same date for all Participants)</li>
                <li>The fees charged are for the Batch period.  </li>
                <li>Unless terminated and or instructed by the Participants, the Batch shall be renewed automatically.</li>
                <li>SIMACES shall have the right to terminate the enrollment on non-payment of the renewal fees within 15 days from the date of renewal or auto-renewal.  </li>
                <li>In case of termination effect of termination shall follow.</li>
            </ol>

            <h2>V. Procedure</h2>

            <ol type="i">
                <li>Communication regarding changes in the specifications of services provided and changes in Rules will be published in the App and on the website www.srifamilyguide.com.  </li>
                <li>If there is any class cancellation or change in the schedule of the program, Participants shall be informed by mail/SMS/Published on App and website. (there are no classes online) </li>
                <li>Participants are responsible for checking for updates from time to time on the App and website. </li>
            </ol>

            <h2>VI. Restrictions</h2>

            <ol type="i">
                <li>This Program is meant to be used only by the Participant who is registered with SIMACES and we reserve the right to take necessary legal actions if the material/content and or videos are shared with anyone without written permission from SIMACES.  </li>
                <li>Participants are not entitled to sell, gift, sublet, copy and or transfer in any form, any material, assets, workbooks, notes etc. provided to them, to a third party. If any Participant finds out about sharing such material and or ID and password with a Third Party then his/her access will be blocked and registration shall be suspended/terminated.  </li>
                <li>Participant’s use of our products i.e. Books/instruments/ video/medium/hardware/material and services provided herein is solely for Participant’s personal and non-commercial use.  </li>
                <li>Use of the Books/instrument/ video/medium/hardware/material or its content other than for personal purposes is prohibited.</li>
            </ol>

            <div style={{ color: 'black' }}>
                <h2 style={{ color: 'black' }}>VII. Risk Acknowledgement</h2>
                <ol type="i">
                    <li>The Advice of the SIMACES is for educational and awareness purposes</li>
                    <li>The SIMACES does not guarantee the future performance of any of the Securities purchased or any specific level of performance, the success of any investment decisions or strategy that SIMACES may use</li>
                    <li>The participant understands that investment advice given to the Participants by the SIMACES is subject to various market, currency, economic, political and business risks and the investment advice is only for awareness and educational purposes.  </li>
                    <li>Participants are well aware that there are risks associated with investing in stocks, bonds, exchange-traded funds, mutual funds, and money market funds and it involves risk of loss.   </li>
                    <li>The Participant expressly agrees and acknowledges that all investments are subject to market and other related risks and there is no assurance or guarantee, whether directly or indirectly, that the value of or return on investments will always be accretive, and that it could depreciate to an unpredictable extent.  </li>
                </ol>
            </div>

            <h2>VIII. Disclaimer</h2>
            <ol type="i">
                <li>Material/books/contents are either obtained from open source, references and or discourses by the luminaries and experts of the field so the responsibility of the content is not of SIMACES and will not be legally responsible for any deficiency/violation of intellectual property on the part of those Teachers / Guide. </li>
                <li>SIMACES is not the manufacturer of all the Books/instruments/ video/medium/hardware/material and hence, any defect relating to the same shall be reported to the manufacturer whose details shall be specified on the packaging SIMACES shall not be in any manner responsible for the same.  </li>
                <li>SIMACES does not provide any guarantee or warranty relating to the Books/instrument/ video/medium/hardware/material. </li>
                <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this App / Course for any particular purpose. Participants acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law. </li>
                <li>SIMACES is not liable for any liabilities including financial penalties, damages, loss, or expenses allegedly caused to Participant, as all the Books/instruments/ video/medium/hardware/material are provided to Participant for education purposes and not business tips.  </li>
                <li style={{ color: 'black' }}>The participant is made aware of the risks associated with investing in securities and Mutual funds and all other investment avenues viz. investing in stocks, bonds, exchange-traded funds, mutual funds, and money market funds involve risk of loss and Loss of principal is possible.  </li>
                <li style={{ color: 'black' }}>Financial advice given is for educational and awareness purposes and SIMACES does not claim any assured or confirmed returns on investment. However financial advice so given shall be used for awareness and educational purposes and shall not form a guarantee. </li>
                <li style={{ color: 'black' }}>SIMACES shall not be liable for any loss or damage caused because of market conditions and or any other conditions. The SIMACES shall also not be liable for any financial loss of the Participant under any condition. </li>
                <li style={{ color: 'black' }}>delay, failure or refusal of the issuers in registering or transferring Securities to the Client’s name or for any interest, dividend or other loss caused to the Client arising therefrom.</li>
            </ol>

            <h2>IX. Transfer of data to third party / Data Sharing</h2>

            <ol type="i">
                <li>Participants authorized SIMACES for the sharing of personal data with third parties and monetize the same and participants herewith also gave their consent.  </li>
                <li>That SIMACES shall have exclusive rights over the data collected from this process.  </li>
                <li>Participants give rights over their personal data, such as the right to access their data, and the right to have their data corrected for the processing of their data. </li>
            </ol>

            <h2>X. Fees</h2>
            <ol type="i">
                <li>Subscription Fees shall be paid in the name of SIMACES Learning LLP.  </li>
                <li>The fee once paid is not refundable under any circumstances.  </li>
                <li>Non-participation in the lectures, programs activities and functions can’t be a reason for the cancellation.  </li>
            </ol>

            <h2>XI. Communication</h2>
            <ol type="i">
                <li>The address for communication shall be which the participant has submitted at the time of the registration. And service of communication on that address shall be deemed to be served.  </li>
                <li>All communication shall be on the registered email address.  </li>
                <li>In case of any change in the address, the Participant needs to specify the same to us in writing on the app well in advance.  </li>
                <li>Any inconsistencies in name or address that will result in non-delivery of the product/communication shall not be the responsibility of SIMACES. </li>
                <li>Before accepting the shipment of any product, kindly ensure that the product’s packaging is not damaged or tampered.  </li>
            </ol>

            <h2>XII. Breach </h2>
            <ol type="i">
                <li>SIMACES may terminate or suspend the participant’s Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if the Participant breaches these Terms and Conditions.</li>
                <li>Upon termination, the Participant’s right to use the Service will cease immediately.  </li>
                <li>If Participant/s wish to terminate their Account, they may simply discontinue using the Service. </li>
                <li>Any violation by the Participant of the terms of this Clause may result in immediate suspension or termination of the Participant’s Account / Registration apart from any legal remedy that the SIMACES can avail.  </li>
                <li>In such instances, the SIMACES may also disclose the Participant’s Account Information if required by any Governmental or legal authority.  </li>
                <li>Participants understand that the violation of these Terms could also result in civil or criminal liability under applicable laws. </li>
            </ol>


            <h2>XIII. Declaration by Participants</h2>
            <ol type="i">
                <li>I have read & understand the above and I am aware of the services SIMACES shall provide to the Participants. </li>
                <li>I Understand that the Participant’s performance depends on the continuing/participating interest on his/her part. </li>
                <li>I Declare and undertake that have read and understood the aforesaid “Rules and Guidelines” and will strictly adhere to the same at all times. </li>
                <li>I further agree that I will fully comply with all decisions made by SIMACES and comply with all decisions made by SIMACES with regard to the above procedures and understand that the same will not be subject to any review under any circumstances.</li>
            </ol>

            <h2>XIV. Acknowledgement</h2>
            <ol type="i">
                <li>These are the Terms and Conditions governing the use of this Service and the agreement that operates between Participant and SIMACES. These Terms and Conditions set out the rights and obligations of all participants regarding the use of the Service. </li>
                <li>Participant's access to and use of the Service is conditioned and sole discretion of the SIMACES.  </li>
                <li>Participant’s acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service. </li>
                <li>By accessing or using the Service participants agree to be bound by these Terms and Conditions. If participants disagree with any part of these Terms and Conditions then they may not access the Service. Please read Our Privacy Policy carefully before using Our Service. </li>
                <li>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect. </li>
            </ol>

            <h2>XV. Dispute Resolution</h2>
            <ol type="i">
                <li>Jurisdiction for all legal cases will only be Pune & all legal expenses shall be borne by the Participant and in case incurred/paid by SIMACES that shall be paid by the Participant to the SIMACES.</li>
                <li>If any dispute, arises out of this then the same shall be resolved by the sole Arbitrator, and all the expenses of the Arbitration proceeding and or all legal proceedings shall be borne by the Participants. The seat of Arbitration shall be at Pune.  </li>
            </ol>

            <h2>XVI. Customer Care</h2>
            <ol type="i">
                <li>SIMACES make all our best endeavours to provide participants with a pleasant experience. In the unlikely event that participants face any issues, please contact us at info@srifamilyguide.com. </li>
            </ol>



        </>
    )

}