import React, { useEffect } from "react";
import styles from "./LaunchPad.module.css";

const LaunchPad = () => {
   const TIME = 15 * 1000;

  useEffect(() => {
    const timeout = setTimeout(() => {
        handleRedirect();
    }, TIME);
    return () => {
        clearTimeout(timeout)
    }
  }, []);

  const handleRedirect = () => {
    window.location.href = "https://srifamilyguide.com"
  };

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img width={120} src={"/sfg.png"} alt="" />
        <p className={styles.message}>
          Sri Family Guide App will be LIVE shortly on the Play Store and App Store
        </p>
        <p className={styles.message}>
          Sri Family Guide अ‍ॅप लवकरच Play Store आणि App Store वर लाईव्ह होईल
        </p>
      </div>
      <div>
        <br />
        <button className={styles.button} onClick={handleRedirect} type="button">Redirect to website</button>
      </div>
    </div>
  );
};

export default LaunchPad;
