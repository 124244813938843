import React from 'react'
import logo from "../../../Assets/siilc-logo.png"
import Barcode from "../../../Assets/barcode.png"

const Invoice = () => {
    return (
        <>

            <div className="invoice-wrapper">
                <div style={{ width: '100%', margin: 'auto', padding: '0px' }}>
                    <div style={{ display: 'table-header-group' }}>
                        <h5 style={{ textAlign: 'center', marginTop: '15px' }}>
                            <b>COURSE FEE RECEIPT</b>
                        </h5>
                        <table style={{ width: '100%', tableLayout: 'fixed', marginBottom: '5px' }}>
                            <tr>
                                <td style={{ verticalAlign: 'bottom' }}>
                                    <div style={{ textAlign: 'left', lineHeight: '1.5', fontSize: '14px', color: '#4a4a4a', verticalAlign: 'middle' }}>
                                        <p>IRN : <span style={{ fontWeight: 'bold' }}>5ca8925799f655005307ad2999efacce01de1ceada205-a5ee408a94c5dc6d3a8</span></p>
                                        <p>Ack No. : <span style={{ fontWeight: 'bold' }}>122419981956254</span></p>
                                        <p>Ack Date : <span style={{ fontWeight: 'bold' }}>31-Jan-24</span></p>
                                    </div>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '50px', lineHeight: '1.5', color: '#323232' }}>
                                    <div style={{ textAlign: 'right', lineHeight: '1.5', fontSize: '14px', color: '#4a4a4a' }}>
                                        <p style={{ marginRight: '50px' }}><b>e-invoice</b></p>
                                        <img width="100" height="100" src={Barcode} style={{ marginLeft: '50px' }} alt="barcode" />
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <table style={{ border: '1px solid #000', width: '100%' }}>
                        <tr>
                            <td style={{ borderRight: '1px solid #000', padding: '5px' }}>
                                <img src={logo} style={{ float: 'left', marginRight: '15px', marginBottom: '5px', width: '100px' }} alt="siilc-logo" />
                                <div style={{ float: 'left', maxWidth: '80%' }}>
                                    <p style={{ fontWeight: 'bold' }}>SIMACES Learning LLP (SIILC)</p>
                                    <p>OFFICE NO 9 AND 10, 5TH FLOOR, AKSHAY COMPLEX, ITI ROAD, AUNDH, PUNE</p>
                                    <p>GSTIN/UIN: <span>27ADGFS8298D2ZJ</span></p>
                                    <p>State Name: <span>Maharashtra, Code: 27</span></p>
                                    <p>E-Mail: <span>accounts@siilc.edu.in</span></p>
                                </div>
                            </td>
                            <td rowspan="2">
                                <table>
                                    <tr>
                                        <td className="cellStyle">
                                            <p>Invoice No.</p>
                                            <p style={{ fontWeight: '700' }}>SLP/0813/2023-24</p>
                                        </td>
                                        <td style={{ borderBottom: '1px solid #000', padding: '4px' }}>
                                            <p>Dated</p>
                                            <p style={{ fontWeight: '700' }}>29-Jan-24</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="cellStyle">
                                            <p>Delivery Note</p>
                                        </td>
                                        <td style={{ borderBottom: '1px solid #000', padding: '4px' }}><p>Mode/Terms of Payment</p></td>
                                    </tr>
                                    <tr>
                                        <td className="cellStyle">
                                            <p>Reference No. & Date</p>
                                        </td>
                                        <td style={{ borderBottom: '1px solid #000', padding: '4px' }}><p>Other References</p></td>
                                    </tr>
                                    <tr>
                                        <td className="cellStyle">
                                            <p>Buyer’s Order No.</p>
                                        </td>
                                        <td style={{ borderBottom: '1px solid #000', padding: '4px' }}><p>Dated</p></td>
                                    </tr>
                                    <tr>
                                        <td className="cellStyle">
                                            <p>Dispatch Doc No.</p>
                                        </td>
                                        <td style={{ borderBottom: '1px solid #000', padding: '4px' }}><p>Delivery Note Date</p></td>
                                    </tr>
                                    <tr>
                                        <td className="cellStyle">
                                            <p>Dispatched through</p>
                                        </td>
                                        <td style={{ borderBottom: '1px solid #000', padding: '4px' }}><p>Destination</p></td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" style={{ padding: '4px' }}>
                                            <p>Terms of Delivery</p>
                                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tenetur, repellendus.</p>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ borderRight: '1px solid #000', borderTop: '1px solid #000', padding: '5px' }}>
                                <div style={{ float: 'left', maxWidth: '80%' }}>
                                    <p>Buyer (Bill to)</p>
                                    <p style={{ fontWeight: 'bold' }}>SHUBH LABH REAL ESTATE CONSULTANTS</p>
                                    <p>Lunkad Dreamland, Viman Nagar Road, Pune</p>
                                    <p>GSTIN/UIN: <span>27AAOPA7275P1ZZ</span></p>
                                    <p>State Name: <span>Maharashtra, Code : 27</span></p>
                                </div>
                            </td>
                        </tr>
                    </table>

                    <table style={{ border: '1px solid #000', width: '100%' }}>
                        <tr className="text-center " style={{ fontSize: '15px' }} >
                            <th style={{ borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>Sr.No</th>
                            <th colSpan="3" style={{ borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>Particulars</th>
                            <th style={{ borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>HSN/SAC</th>
                            <th style={{ borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>Rate</th>
                            <th style={{ borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>Per</th>
                            <th style={{ borderBottom: '1px solid #000' }}>Amount</th>
                        </tr>

                        <tr style={{ fontSize: '14px' }}>
                            <td style={{ borderRight: '1px solid #000', textAlign: 'center' }}>01</td>
                            <td colSpan="3" style={{ borderRight: '1px solid #000', padding: '4px' }}>
                                <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Real Estates Course Fees</p>
                                <p style={{ textAlign: 'right', fontWeight: 'bold' }}>Output CGST @ 9%</p>
                                <p style={{ textAlign: 'right', fontWeight: 'bold' }}>Output SGST @ 9%</p>
                            </td>
                            <td style={{ borderRight: '1px solid #000' }}>999293</td>
                            <td style={{ borderRight: '1px solid #000' }}>
                                <p style={{ textAlign: 'right', fontWeight: 'bold', textAlign: 'center' }}>9</p>
                                <p style={{ textAlign: 'right', fontWeight: 'bold', textAlign: 'center' }}>9</p>
                            </td>
                            <td style={{ borderRight: '1px solid #000', textAlign: 'center' }}>
                                <p>%</p>
                                <p>%</p>
                            </td>
                            <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                <p>5,000.00</p>
                                <p>450.00</p>
                                <p>450.00</p>
                            </td>
                        </tr>

                        <tr style={{ fontSize: '14px' }}>
                            <td style={{ borderRight: '1px solid #000', borderTop: '1px solid #000' }}></td>
                            <td colSpan="3" style={{ borderRight: '1px solid #000', borderTop: '1px solid #000', textAlign: 'end' }}>
                                Total
                            </td>
                            <td style={{ borderRight: '1px solid #000', borderTop: '1px solid #000' }}></td>
                            <td style={{ borderRight: '1px solid #000', borderTop: '1px solid #000' }}></td>
                            <td style={{ borderRight: '1px solid #000', borderTop: '1px solid #000' }}></td>
                            <td style={{ borderTop: '1px solid #000', fontWeight: 'bold' }}>Rs 5,900.00</td>
                        </tr>
                    </table>

                    <table style={{ border: '1px solid #000' }}>
                        <tr>
                            <td style={{ padding: "10px" }}>
                                <p>Amount Chargeable (in words)</p>
                                <h6 className="fw-bold" style={{ margin: "0", padding: "0" }}>INR Five Thousand Nine Hundred Only</h6>
                            </td>
                            <td style={{ textAlign: "right", padding: "10px" }}>
                                <p>E & O.E</p>
                            </td>

                        </tr>
                    </table>

                    <table style={{ border: '1px solid #000' }}>
                        <tr align="center" style={{ fontSize: '14px', fontWeight: 'bold' }}>
                            <td style={{ borderRight: "1px solid #000" }} rowspan="2">HSN/SAC</td>
                            <td style={{ borderRight: "1px solid #000" }} rowspan="2">Taxable Value</td>
                            <td colspan=" 2" style={{ borderRight: "1px solid #000" }}>CGST</td>
                            <td colspan="2" style={{ borderRight: "1px solid #000" }}>SGST/UTGST</td>
                            <td>Total Tax Amount</td>
                        </tr>

                        <tr align="center fw-bold">
                            <td style={{ borderRight: "1px solid #000", borderTop: "1px solid #000" }}>Rate</td>
                            <td style={{ borderRight: "1px solid #000", borderTop: "1px solid #000" }}>Amount</td>
                            <td style={{ borderRight: "1px solid #000", borderTop: "1px solid #000" }}>Rate</td>
                            <td style={{ borderRight: "1px solid #000", borderTop: "1px solid #000" }}>Amount</td>
                        </tr>

                        <tr align="center" style={{ fontSize: '14px' }}>
                            <td style={{ borderRight: "1px solid #000", borderTop: "1px solid #000" }}>999293</td>
                            <td style={{ borderRight: "1px solid #000", borderTop: "1px solid #000" }}>5000.00</td>
                            <td style={{ borderRight: "1px solid #000", borderTop: "1px solid #000" }}>9%</td>
                            <td style={{ borderRight: "1px solid #000", borderTop: "1px solid #000" }}>450.00</td>
                            <td style={{ borderRight: "1px solid #000", borderTop: "1px solid #000" }}>9%</td>
                            <td style={{ borderRight: "1px solid #000", borderTop: "1px solid #000" }}>450.00</td>
                            <td style={{ borderTop: "1px solid #000" }}>900.00</td>
                        </tr>
                        <tr align="center" style={{ fontSize: '14px' }}>
                            <td style={{ borderRight: "1px solid #000", borderTop: "1px solid #000", fontWeight: "bold" }}>Total</td>
                            <td style={{ borderRight: "1px solid #000", borderTop: "1px solid #000", fontWeight: "bold" }}>5000.00
                            </td>
                            <td style={{ borderRight: "1px solid #000", borderTop: "1px solid #000", fontWeight: "bold" }}>-</td>
                            <td style={{ borderRight: "1px solid #000", borderTop: "1px solid #000", fontWeight: "bold" }}>450.00</td>
                            <td style={{ borderRight: "1px solid #000", borderTop: "1px solid #000", fontWeight: "bold" }}>-</td >
                            <td style={{ borderRight: "1px solid #000", borderTop: "1px solid #000", fontWeight: "bold" }}>450.00</td >
                            <td td style={{ borderTop: "1px solid #000", fontWeight: "bold" }}> 900.00</td>
                        </tr >

                    </table >

                    <table style={{ border: '1px solid #000' }}>
                        <tr>
                            <td style={{ padding: "5px" }}>
                                <div style={{ float: "left", maxWidth: "80%" }}>
                                    <p>
                                        Tax Amount (in words) : <span style={{ fontWeight: 'bold' }}>INR Nine Hundred Only</span>
                                    </p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td rowspan="2" style={{ padding: "5px" }}>
                                <div style={{ float: "right" }}>
                                    <p style={{ fontSize: '14px' }}>
                                        Company’s Bank Details
                                    </p>
                                    <p>
                                        Bank Name: <span style={{ fontWeight: 'bold' }}>YES BANK</span>
                                    </p>
                                    <p>
                                        A/c No.: <span style={{ fontWeight: 'bold' }}>: 055181400001278</span>
                                    </p>
                                    <p>
                                        Branch & IFS Code: <span style={{ fontWeight: 'bold' }}>BUND GARDEN, PUNE &
                                            YESB0000551</span>
                                    </p>
                                </div>
                            </td>
                        </tr>

                    </table>

                    <table style={{ borderBottom: '1px solid #000', borderLeft: "1px solid #000", borderRight: '1px solid #000' }}>
                        <tr>
                            <td style={{ borderRight: '1px solid #000', padding: '5px', verticalAlign: 'bottom' }}>
                                <div style={{ float: "left", maxWidth: "80%" }}>
                                    <p>
                                        Company’s PAN: <span style={{ fontWeight: 'bold' }}>ADGFS8298D</span>
                                    </p>
                                    <p style={{ fontSize: '15px' }}>
                                        Declaration
                                    </p>
                                    <p style={{ margin: '0', padding: '0', fontSize: '12px' }}>
                                        CHEQUE PAYMENT IS SUBJECT TO REALISATION.
                                    </p>

                                </div>
                            </td>
                            <td rowspan="2" style={{ padding: "5px" }}>

                                <p align="center" style={{ fontWeight: 'bold' }}>
                                    for SIMACES Learning LLP (SIILC)
                                </p>
                                <p align="end" style={{ marginTop: "20px" }}>
                                    Authorised Signatory
                                </p>
                            </td>
                        </tr>
                    </table>

                    <div align="center" style={{ marginTop: '20px' }}>
                        <p style={{ fontSize: '16px' }}>
                            SUBJECT TO PUNE JURISDICTION
                        </p>
                        <p style={{ fontSize: '15px' }}>
                            This is a Computer Generated Invoice
                        </p>
                    </div>
                </div >
            </div >

        </>
    )
}


export default Invoice
