import React, { useState } from 'react';
import { Card, Alert, Button } from 'react-bootstrap'; // Import Alert and Button from react-bootstrap
import { X, Plus, Calendar2Week, CheckLg } from 'react-bootstrap-icons';
import Familygrp from "../../../Assets/familygrp.svg";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ApiCalling } from '../../../Services/Pool';
import { showToastSuccess, showToastWarning } from '../../../Utilities/ToastUtils';

const EventConfirm = () => {
    const navigate = useNavigate()

    const location = useLocation()
    const eventID = location.state.eventID
    const selectedSlot = location.state.data
    const lang = location.state.lang
    const token = location.state.token
    const userDetails = location.state.userdetails

    // get dob from user
    const userBirth = location?.state?.userdetails?.dob

    const [selectedButtons, setSelectedButtons] = useState([]);
    const [seniorCitizenCount, setSeniorCitizenCount] = useState(0);
    const [adultCount, setAdultCount] = useState(0);
    const [childCount, setChildCount] = useState(0);
    const [showAdd, setShowAdd] = useState(false);
    const [loader, setLoader] = useState(false);
    const [apiError, setApiError] = useState(false)

    const [seniorCitizenError, setSeniorCitizenError] = useState('');
    const [adultError, setAdultError] = useState('');
    const [childError, setChildError] = useState('');
    const [familyMemberNames, setFamilyMemberNames] = useState({});


    const maxSeniorCitizens = 2;
    const maxAdults = 2;
    const maxChildren = 3;
    const [alertMessage, setAlertMessage] = useState(null);



    // for birth date age calculation
    const calculateAge = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);
        let age = today.getFullYear() - birth.getFullYear();
        const monthDiff = today.getMonth() - birth.getMonth();

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
            age--;
        }
        return age;
    };

    const age = calculateAge(userBirth);

    // Determine the appropriate label based on age
    let firstCardLabel;
    if (age >= 60) {
        firstCardLabel = "Senior Citizen";
    } else if (age >= 16) {
        firstCardLabel = "Adult";
    } else {
        firstCardLabel = "Child";
    }


    const handleButtonClick = (buttonType) => {
        setSeniorCitizenError('');
        setAdultError('');
        setChildError('');

        // Check if the selected button matches the family card label
        const isMatchingLabel = firstCardLabel === buttonType;
        const isAlreadySelected = selectedButtons.includes(buttonType);

        // Check if the selected button should be added only once
        const shouldAddOnce = isMatchingLabel && isAlreadySelected;

        if (shouldAddOnce) {
            let maxAllowed;
            switch (buttonType) {
                case 'Senior Citizen':
                    maxAllowed = maxSeniorCitizens;
                    setAlertMessage(`Maximum ${maxAllowed} senior citizens allowed.`);
                    break;
                case 'Adult':
                    maxAllowed = maxAdults;
                    setAlertMessage(`Maximum ${maxAllowed} adults allowed.`);
                    break;
                case 'Child':
                    maxAllowed = maxChildren;
                    setAlertMessage(`Maximum ${maxAllowed} children allowed.`);
                    break;
                default:
                    setAlertMessage(null);
            }
            return;
        }

        if (buttonType === 'Senior Citizen' && seniorCitizenCount >= maxSeniorCitizens) {
            setAlertMessage('Maximum 2 senior citizens allowed.');
            return;
        }

        if (buttonType === 'Adult' && adultCount >= maxAdults) {
            setAlertMessage('Maximum 2 adults allowed.');
            return;
        }

        if (buttonType === 'Child' && childCount >= maxChildren) {
            setAlertMessage('Maximum 3 children allowed.');
            return;
        }

        setSelectedButtons([...selectedButtons, buttonType]);

        if (buttonType === 'Senior Citizen') {
            setSeniorCitizenCount(seniorCitizenCount + 1);
        } else if (buttonType === 'Adult') {
            setAdultCount(adultCount + 1);
        } else if (buttonType === 'Child') {
            setChildCount(childCount + 1);
        }
    };


    const handleRemoveInput = (buttonType) => {
        setSelectedButtons(prevButtons => {
            const indexToRemove = prevButtons.findIndex(btn => btn === buttonType);
            if (indexToRemove !== -1) {
                prevButtons.splice(indexToRemove, 1);
            }
            return [...prevButtons];
        });

        // Use functional form of setState to ensure correct state updates
        if (buttonType === 'Senior Citizen') {
            setSeniorCitizenCount(prevCount => prevCount - 1);
        } else if (buttonType === 'Adult') {
            setAdultCount(prevCount => prevCount - 1);
        } else if (buttonType === 'Child') {
            setChildCount(prevCount => prevCount - 1);
        }
    };


    const renderContent = () => {
        if (selectedButtons.length === 0) {
            return (
                <div className='my-4 d-flex flex-column align-items-center'>
                    <img src={Familygrp} alt="" />
                    <p className="text-center w-75" style={{ color: '#888888' }}>You may add up to 6 additional family members!</p>
                </div>
            );
        }
    };

    const handleInputChange = (event, buttonType) => {
        const { value } = event.target;
        setFamilyMemberNames(prevState => ({
            ...prevState,
            [buttonType]: value
        }));
    };


    // for api calling
    const handleBookEvent = async (eventID, selectedSlot) => {
        setLoader(true)
        setSeniorCitizenError('');
        setAdultError('');
        setChildError('');

        // Validate input fields
        if (selectedButtons.includes('Senior Citizen') && seniorCitizenCount === 0) {
            setSeniorCitizenError('Please add at least one senior citizen.');
            setLoader(false);
            return;
        }

        if (selectedButtons.includes('Adult') && adultCount === 0) {
            setAdultError('Please add at least one adult.');
            setLoader(false);
            return;
        }

        if (selectedButtons.includes('Child') && childCount === 0) {
            setChildError('Please add at least one child.');
            setLoader(false);
            return;
        }

        const familyMemberNamesArray = Object.values(familyMemberNames);
        if (familyMemberNamesArray.some(name => name.trim() === '')) {
            setAlertMessage('Please provide names for all family members.');
            setLoader(false);
            return;
        }
        try {
            let familyMembers = [];
            familyMembers.push({
                type: firstCardLabel,
                name: userDetails?.name
            });
            selectedButtons.forEach(buttonType => {
                const name = familyMemberNames[buttonType] || '';
                familyMembers.push({
                    type: buttonType,
                    name: name
                });
            });
            const payload = {
                eventId: eventID,
                slot: [
                    {
                        "_id": selectedSlot?._id,
                        "batch": selectedSlot?.batch,
                        "date": selectedSlot?.date,
                        "hallNumber": selectedSlot?.hallNumber,
                        "slots": selectedSlot?.slots,
                        "bookingLimit": selectedSlot?.bookingLimit,
                        "booked": selectedSlot?.booked

                    }
                ],
                familyMembers: familyMembers,
                lang: lang
            }

            const response = await fetch(

                `${ApiCalling.base_url_v1}${ApiCalling.paths.bookEvent}`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json", "authorization": token },
                    body: JSON.stringify(payload)
                }
            );
            const responseData = await response.json();
            setLoader(false)
            if (responseData?.message === 'Already Registered') {
                setLoader(false)
                showToastWarning("Event Already Booked.")
                setShowAdd(false)
            } else if (responseData?.message === 'Register Successfully') {
                setLoader(false)
                showToastSuccess("Event Booked Succassfully !")
                navigate(`/event-details/${token}/${lang}`)
                setShowAdd(false)
                // getEventData()
                setTimeout(() => {
                    var qrCodeContainer = document.getElementById('qrCodeContainer');
                    if (qrCodeContainer) {
                        qrCodeContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                }, 1000);
            }
        } catch (error) {
            setApiError(true)
            console.log("error")
        }
    }

    const renderInputCard = (buttonType) => {
        let errorMessage = '';
        if (buttonType === 'Senior Citizen') {
            errorMessage = seniorCitizenError;
        } else if (buttonType === 'Adult') {
            errorMessage = adultError;
        } else if (buttonType === 'Child') {
            errorMessage = childError;
        }
        return (
            <Card key={buttonType} className='mt-3'>
                <Card.Body>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <p style={{ color: '#888888', fontWeight: 'bold' }}>Add {buttonType}</p>
                        </div>
                        <div style={{ cursor: 'pointer' }} onClick={() => handleRemoveInput(buttonType)}>
                            <X color="#888888" size={24} />
                        </div>
                    </div>
                    <div className="mt-3">
                        <input type="text" className="form-control mb-2" placeholder="Name of family member" onChange={(event) => handleInputChange(event, buttonType)} required />
                        {alertMessage && (
                            <Alert variant="warning" onClose={() => setAlertMessage(null)} dismissible>
                                <p>{alertMessage}</p>
                            </Alert>
                        )}
                    </div>
                </Card.Body>
            </Card>
        );
    };


    return (
        <div className='container p-3'>
            <div className='' style={{ overflowX: 'scroll', height: '80vh' }}>
                <h1 style={{ fontSize: "24px", fontWeight: "400" }}>Add Family Members</h1>
                <p style={{ color: "#888888", fontSize: "14px", fontWeight: "400" }}>Entries available for 2 senior citizens, 2 adults, and 3 children</p>
                <div className='d-flex gap-3 mt-4 mb-5 '>
                    <button className='btn btn-light border py-2' onClick={() => handleButtonClick('Senior Citizen')}><Plus size={20} />Senior Citizen</button>
                    <button className='btn btn-light border py-2 ' onClick={() => handleButtonClick('Adult')}><Plus size={20} />Adult</button>
                    <button className='btn btn-light border py-2 ' onClick={() => handleButtonClick('Child')}><Plus size={20} />Child</button>
                </div>
                <div>
                    <h2 className='d-flex gap-2' style={{ fontSize: "16px", fontWeight: "500" }}><Calendar2Week style={{ fontSize: "20px" }} />Your booking is almost ready</h2>
                    <div>
                        <Card>
                            <Card.Body>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        <p className="m-0" style={{ color: '#888888' }}>1 {firstCardLabel}</p>
                                        <h6 className='fw-bold'>{location?.state?.userdetails?.name}</h6>
                                    </div>
                                    <div >
                                        <CheckLg color="#888888" size={24} />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>

                        <div className='mt-4'>
                            <h2 className='fw-bold' style={{ fontSize: "16px", fontWeight: "500" }}>
                                <img src={Familygrp} alt="" width={35} />
                                <span className='ms-2'>Add Your Family Members ({selectedButtons.length + 1}/6)</span>
                            </h2>
                            {selectedButtons.map(buttonType => renderInputCard(buttonType))}
                        </div>
                        {renderContent()}
                    </div>
                </div>

                <div className='' style={{ position: "fixed", inset: "0", zIndex: '999', top: "unset", padding: "40px 10px 10px" }}>
                    {alertMessage && (
                        <Alert variant="warning" onClose={() => setAlertMessage(null)} dismissible>
                            <p >{alertMessage}</p>
                        </Alert>
                    )}
                    <button style={{ backgroundColor: "#EA6025", borderColor: "#EA6025", borderRadius: "100px", fontSize: "16px", fontWeight: "700", fontFamily: "Open Sans", }} className='btn btn-primary w-100 py-3' onClick={() => handleBookEvent(eventID, selectedSlot)}>Confirm</button>
                </div>
            </div>
        </div>
    );
};

export default EventConfirm;
