import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonToggle from '../../../CustomHookes/ButtonToggle';

const ReuseCode = ({ headerImage, Back, familygLogo, title, subTitle, changeNo, endPoint, onLanguageChange, selectedLanguage }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentUrl = location.pathname;
    const [activeKey, setActiveKey] = useState("mr");



    useEffect(() => {
        const storedLanguage = localStorage.getItem("language");
        if (storedLanguage) {
            setActiveKey(storedLanguage);
        }
    }, []);

    return (
        <div>
            <div className='mobileWrapper'>
                <div className='headerImage'>
                    <img src={headerImage} alt="Header" />
                </div>
                {currentUrl !== "/signinup" && currentUrl !== "/mobilenumber" && <div className='BackImage'>
                    <img src={Back} alt="Back" onClick={() => navigate(endPoint)} />
                </div>}

                <div className='headerLogo'>
                    <img src={familygLogo} alt="Logo" />

                    {/* for toggle language */}
                    {currentUrl === "/signinup" || currentUrl === "/mobilenumber" && <div style={{ marginRight: '15px' }}>
                        <ButtonToggle selectedLanguage={selectedLanguage} onLanguageChange={onLanguageChange} />
                        {/* <div style={{ marginRight: '15px' }}>
                            <ButtonGroup
                                aria-label="Basic example"
                                style={{ border: '1px solid #FCFFFF', borderRadius: '50px', overflow: 'hidden' }}
                            >
                                <Button
                                    variant="light"
                                    className="border-right"
                                    active={activeKey === 'mr'}
                                    onClick={() => handleButtonClick('mr')}
                                    style={{ backgroundColor: activeKey === 'mr' ? '#FCFFFF' : '#FD6E47', color: activeKey === 'mr' ? '#000' : '#FCFFFF', borderRight: '1px solid #FCFFFF', }}
                                >
                                    MR
                                </Button>
                                <Button
                                    variant="light"
                                    className="border-right"
                                    active={activeKey === 'hn'}
                                    onClick={() => handleButtonClick('hn')}
                                    style={{
                                        backgroundColor: activeKey === 'hn' ? '#FCFFFF' : '#FD6E47',
                                        color: activeKey === 'hn' ? '#000' : '#FCFFFF',
                                        borderRight: '1px solid #FCFFFF',
                                    }}
                                >
                                    HN
                                </Button>
                                <Button
                                    variant="light"
                                    className="border-right"
                                    active={activeKey === 'en'}
                                    onClick={() => handleButtonClick('en')}
                                    style={{ backgroundColor: activeKey === 'en' ? '#FCFFFF' : '#FD6E47', color: activeKey === 'en' ? '#000' : '#FCFFFF' }}
                                >
                                    EN
                                </Button>
                            </ButtonGroup>
                        </div> */}
                    </div>}

                    {endPoint === "/mobilenumber" &&
                        <div onClick={() => navigate(endPoint)} className="d-block ">
                            <button style={{ backgroundColor: 'transparent', border: 'none', color: 'white', marginRight: '15px' }}>
                                {changeNo}
                            </button>
                        </div>}
                </div>
                <div className='headerText' style={{ width: '350px' }}>
                    <h4>{title}</h4>
                    <h4>{subTitle}</h4>
                </div>
            </div>
        </div>
    );
}

export default ReuseCode;

